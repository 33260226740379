import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export const nullIfEmptyValidator = (control: AbstractControl) => {
  if (control.value === '') {
    control.setValue(null);
  }
  return null;
};

export const wifiNameAndPasswordValidator = (control: AbstractControl): ValidationErrors | null => {
  const wifiNameControl = control.get('wifiName');
  const wifiPasswordControl = control.get('wifiPassword');

  if (wifiPasswordControl?.value && !wifiNameControl?.value) {
    wifiNameControl?.setErrors({ required: true });
  } else {
    wifiNameControl?.setErrors(null);
  }
  return null;
};

export function passwordFormValidator(newPassControlName: string, oldPassControlName: string, enable: boolean): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (!enable) {
      return null;
    }

    const newPassCtrl = group.get(newPassControlName);
    const oldPassCtrl = group.get(oldPassControlName);

    if (newPassCtrl?.value && !oldPassCtrl?.value) {
      oldPassCtrl?.setErrors({ required: true });
      oldPassCtrl?.markAsDirty();
      oldPassCtrl?.markAsTouched();
    } else if (oldPassCtrl?.value && !newPassCtrl?.value) {
      newPassCtrl?.setErrors({ required: true });
      newPassCtrl?.markAsDirty();
      newPassCtrl?.markAsTouched();
    } else {
      removeError(oldPassCtrl, 'required');
      removeError(newPassCtrl, 'required');
    }

    return null;
  };
}

function removeError(control: AbstractControl | null, errorKey: any): void {
  const errors: any = control?.errors;
  let processedErrors: ValidationErrors | null = {};

  if (errors && errors instanceof Object) {
    Object.keys(errors).forEach((key: string) => {
      if (key !== errorKey && processedErrors && errors) {
        processedErrors[key] = errors[key];
      }
    });
  }

  if (Object.keys(processedErrors).length < 1) {
    processedErrors = null;
  }

  control?.setErrors(processedErrors);
}
