import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import {BehaviorSubject, fromEvent, merge, of, Subject, Subscription, timer} from 'rxjs';
import {
  catchError,
  delay, delayWhen,
  distinctUntilChanged,
  filter,
  map,
  share,
  switchMap, tap,
} from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConnectionStatusService } from '../connection-status.service';

@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  public inboundMessage$ = new Subject<any>();
  private outboundMessage$ = new Subject<any>();

  private open$ = new Subject<any>();
  private closing$ = new Subject<any>();
  private close$ = new Subject<any>();

  private wsSub: Subscription | null = null;
  private timeoutSub: Subscription | null = null;
  private heartbeatSub: Subscription | null = null;
  private outboundMessageSub: Subscription | null = null;
  private updateMenuSub: Subscription | null = null;

  private readonly wsSubject$ = webSocket({
    url: `${environment.socketApi}/ws/main`,
    openObserver: this.open$,
    closingObserver: this.closing$,
    closeObserver: this.close$,
  });

  public connected$ = new BehaviorSubject<boolean>(false);
  public authorized$ = new BehaviorSubject<boolean>(false);

  constructor(
    private connectionStatus: ConnectionStatusService
  ) {
    this.connectionStatus.online$.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((status) => {
      if (status) {
        console.log('Connection status: online');
        this.subscribeWebSocket();
      } else {
        console.warn('Connection status: offline');
        this.unsubscribeWebSocket();
      }
    });

    fromEvent(document, 'visibilitychange').pipe(
      map(() => document.visibilityState === 'visible'),
      distinctUntilChanged(),
      filter((visible) => visible),
      filter(() => this.connectionStatus.online$.getValue()),
      filter(() => !this.connected$.getValue()),
      untilDestroyed(this)
    ).subscribe(() => {
      this.subscribeWebSocket();
    });

    this.open$.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      console.log('WebSocket: Open');
      this.connected$.next(true);
    });

    this.closing$.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      console.log('WebSocket: Closing');
    });

    this.close$.pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      console.log('WebSocket: Close');
      this.connected$.next(false);
      this.authorized$.next(false);
    });
  }

  private unsubscribeWebSocket(): void {
    if (this.wsSub) {
      console.log(`WebSocket: Unsubscribe`);
    }

    this.wsSub?.unsubscribe();
    this.wsSub = null;
    this.timeoutSub?.unsubscribe();
    this.timeoutSub = null;
    this.heartbeatSub?.unsubscribe();
    this.heartbeatSub = null;
    this.outboundMessageSub?.unsubscribe();
    this.outboundMessageSub = null;
    this.updateMenuSub?.unsubscribe();
    this.updateMenuSub = null;
  }

  private subscribeWebSocket(): void {
    this.unsubscribeWebSocket();

    console.log(`WebSocket: Start`);

    const wsMessages$ = this.wsSubject$.pipe(
      tap((r) => console.log('wsMessages', r)),
      filter((response: any) => !!(response?.type)),
      share(),
    );

    this.timeoutSub = merge(of('init'), wsMessages$).pipe(
      switchMap(() => of('Restart with timeout').pipe(
        delay(10000)
      )),
      catchError(() => of('Restart with error').pipe(
        delay(3000)
      )),
      untilDestroyed(this),
    ).subscribe((msg) => {
      console.log(`WebSocket: ${msg}`);

      this.subscribeWebSocket();
    });

    this.heartbeatSub = timer(1000, 5000).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      console.log('WebSocket: Heartbeat send');
      this.wsSubject$.next({ type: 'heartbeat' });
    });

    this.wsSub = wsMessages$.pipe(
      untilDestroyed(this),
    ).subscribe(
      (response: any) => {
        if (response.type === 'loginSuccessful') {
          this.authorized$.next(true);
        }

        this.inboundMessage$.next(response);
      },
      (error) => console.error('WebSocket:', error),
      () => console.log('WebSocket: Complete'),
    );

    this.updateMenuSub = wsMessages$.pipe(
      untilDestroyed(this),
    ).subscribe((menu) => {
      this.sendMessage({ type: 'updateMenu', data: menu });
    });

    this.outboundMessageSub = this.outboundMessage$.pipe(
      delayWhen(() => this.authorized$.pipe(
        filter((status) => status)
      ))
    ).subscribe((message) => {
      this.wsSubject$.next(message);
    });
  }

  sendMessage(message: any, force: boolean = false): void {
    if (force) {
      this.wsSubject$.next(message);
    } else {
      this.outboundMessage$.next(message);
    }
  }

  auth(JWT: string): void {
    if (!this.connected$.getValue()) {
      this.subscribeWebSocket();
    }

    this.sendMessage({ type: 'loginDashboard', data: { JWT } }, true);
  }

  subscribeToRestaurant(id: number): void {
    this.sendMessage({ type: 'channel.subscribe', data: { channel: `restaurant:${id}` }});
  }

  subscribeToChain(id: number): void {
    this.sendMessage({ type: 'channel.subscribe', data: { channel: `chain:${id}` }});
  }

  unsubscribeToRestaurant(id: number): void {
    this.sendMessage({ type: 'channel.unsubscribe', data: { channel: `restaurant:${id}` }});
  }

  unsubscribeToChain(id: number): void {
    this.sendMessage({ type: 'channel.unsubscribe', data: { channel: `chain:${id}` }});
  }

  subscribe(next: (message: any) => void, error?: (error: any) => void, complete?: () => void): void {
    this.inboundMessage$.pipe(
      untilDestroyed(this),
    ).subscribe(next, error, complete);
  }

  close(): void {
    this.unsubscribeWebSocket();
  }


}
