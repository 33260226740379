import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { pluck, tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../../services/admin/admin.service';
import { Chain } from '../../../interfaces/chain';
import { IChainMenu } from '../../../interfaces/menu';
import { environment } from '../../../../environments/environment';
import { AddChainMenuComponent } from '../../../components/modals/add-chain-menu/add-chain-menu.component';
import {WebsocketService} from '../../../services/websocket/websocket.service';

@UntilDestroy()
@Component({
  selector: 'app-chain-happy-hours-page',
  templateUrl: './chain-happy-hours-page.component.html',
  styleUrls: ['./chain-happy-hours-page.component.scss']
})
export class ChainHappyHoursPageComponent implements OnInit {
  public env = environment;

  public chain!: Chain;
  public chainMenus: IChainMenu[] = [];
  public isLoading = false;
  public allRestaurants: IRestaurantInfo[] = [];
  public timeFrom: any;
  public timeTo: any;
  public data: any;
  public file: any;
  public happyHours: {day: number, name: string, status: boolean, timeFrom: string | null, timeTo: string | null}[] = [];
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public isEdit = false;

  constructor(
    private activateRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notify: NotifierService,
    private translate: TranslateService,
    private adminService: AdminService,
    private menuStore: MenuStoreService,
    private websocket: WebsocketService
  ) { }

  ngOnInit(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.chain = chain;
        this.initMenu();
        this.getRestaurants(chain.id);
      }),
      untilDestroyed(this),
    ).subscribe();
    this.initWebsocket();
  }

  initMenu(): void {
    this.menuStore.fetchChainMenus({
      chainId: this.chain.id,
      menuType: 'happyMenu',
    });

    this.menuStore.getChainMenus$.
    pipe(untilDestroyed(this))
      .subscribe((menus) => {
        this.chainMenus = menus;
      });

    this.menuStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => this.isLoading = load);
  }

  private initWebsocket(): void {
    this.websocket.inboundMessage$.pipe(
      untilDestroyed(this)
    ).subscribe((message: any) => {
      if (message.type === 'updateMenu') {
        this.menuStore.fetchChainMenu(this.chain.id, message.data.id);
      }
    });
  }

  public createChainMenu(): void {
    this.dialog.open(AddChainMenuComponent, {
      data: { chainId: this.chain.id, happyHours: true, allRestaurants: this.allRestaurants },
    });
  }

  public getRestaurants(chainId: number): void {
    this.adminService.getRestaurantsByChainId(chainId).subscribe((restaurants) => {
      this.allRestaurants = restaurants;
    });
  }
}

