import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from 'angular-notifier';
import { IChainMenu, IMenu } from '../../interfaces/menu';
import {share } from 'rxjs/operators';
import { createHttpParams } from '../../shared/function';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private notifier: NotifierService,
  ) {}

  public addMenu(
    restaurantId: number,
    pdfFileId: string,
    name: string,
    happyHours: boolean = false,
    hidden: boolean = false,
    iconFileId?: string | null
  ): Observable<IMenu> {
    const formData = new FormData();
    formData.append('restaurantId', restaurantId.toString());
    formData.append('happyHours', `${happyHours}`);
    formData.append('hidden', `${hidden}`);
    formData.append('name', name);
    formData.append('pdfFileId', pdfFileId);

    if (iconFileId) {
      formData.append('iconFileId', iconFileId);
    }

    const subject$ = this.http.post<IMenu>(`/restaurants/${restaurantId}/menus`, formData).pipe(
      share()
    );

    subject$.subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.menu.messages.uploadMenu'));
    });

    return subject$;
  }

  public getRestaurantMenus({
    restaurantId,
    sort = 'createdAt',
    order = 'DESC',
    menuType = '',
  }: {
    restaurantId: number;
    sort?: string;
    order?: string;
    menuType?: string;
  }): Observable<IMenu[]> {
    const params = createHttpParams({ restaurantId, sort, order, menuType });

    const subject$ = this.http.get<IMenu[]>(`/restaurants/${restaurantId}/menus`, {
        params,
    }).pipe(share());

    subject$.subscribe();

    return subject$;
  }

  public getRestaurantMenuById(restaurantId: number, menuId: number): Observable<IMenu> {
    return this.http.get<IMenu>(`/restaurants/${restaurantId}/menus/${menuId}`).pipe(share());
  }

  public updateMenu(
    restaurantId: number,
    menuId: number,
    name: string,
    happyHours: boolean,
    hidden: boolean,
    menuFileId: string | null,
    iconFile?: string | null
  ): Observable<any> {
    const formData = new FormData();
    formData.append('happyHours', `${happyHours}`);
    formData.append('hidden', `${hidden}`);
    formData.append('name', name);
    if (menuFileId) {
      formData.append('pdfFileId', menuFileId);
    }
    if (iconFile) {
      formData.append('iconFileId', iconFile);
    }

    const subject$ = this.http.patch(`/restaurants/${restaurantId}/menus/${menuId}`, formData).pipe(share());

    subject$.subscribe((message) => {
      this.notifier.notify('success', this.translate.instant('pages.menu.messages.updateMenu'));
    });

    return subject$;
  }

  public getChainMenus({
    chainId,
    sort = 'createdAt',
    order = 'DESC',
    menuType = '',
  }: {
    chainId: number;
    sort?: string;
    order?: string;
    menuType?: string;
  }): Observable<IChainMenu[]> {
    const params = createHttpParams({ sort, order, menuType });

    const subject$ = this.http.get<IChainMenu[]>(`/chains/${chainId}/menus`, {
      params,
    }).pipe(share());

    subject$.subscribe();

    return subject$;
  }

  public getChainMenuById(chainId: number, menuId: number): Observable<IChainMenu> {
    return this.http.get<IChainMenu>(`/chains/${chainId}/menus/${menuId}`).pipe(share());
  }

  public addChainMenu(
    chainId: number,
    pdfFileId: string,
    name: string,
    happyHours: boolean = false,
    hidden: boolean = false,
    selectedRestaurants: number[] | null,
    iconFileId: string | null
  ): Observable<IChainMenu> {
    const formData = new FormData();
    formData.append('chainId', chainId?.toString());
    formData.append('happyHours', `${happyHours}`);
    formData.append('hidden', `${hidden}`);
    formData.append('name', name);
    formData.append('restaurantIdsToAttach', JSON.stringify(selectedRestaurants));
    formData.append('pdfFileId', pdfFileId);

    if (iconFileId) {
      formData.append('iconFileId', iconFileId);
    }

    const subject$ = this.http.post<IChainMenu>(`/chains/${chainId}/menus`, formData).pipe(
      share()
    );

    subject$.subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.menu.messages.uploadMenu'));
    });

    return subject$;
  }

  public updateChainMenu(
    menuId: number,
    chainId: number,
    name: string,
    happyHours: boolean,
    hidden: boolean,
    selectedRestaurants: number[] | null,
    menuFileId: string | null,
    iconFileId: string | null
  ): Observable<any> {
    const formData = new FormData();
    formData.append('chainId', chainId?.toString());
    formData.append('happyHours', `${happyHours}`);
    formData.append('name', name);
    formData.append('hidden', `${hidden}`);
    formData.append('restaurantIdsToAttach', JSON.stringify(selectedRestaurants));
    if (menuFileId) {
      formData.append('pdfFileId', menuFileId);
    }
    if (iconFileId) {
      formData.append('iconFileId', iconFileId);
    }

    const subject$ = this.http.patch(`/chains/${chainId}/menus/${menuId}`, formData).pipe(
      share()
    );

    subject$.subscribe((message) => {
      this.notifier.notify('success', this.translate.instant('pages.menu.messages.updateMenu'));
    });

    return subject$;
  }

  public deleteRestaurantMenu(restaurantId: number, menuId: number): Observable<{ message: string }> {
    const subject$ = this.http.delete<{ message: string }>(`/restaurants/${restaurantId}/menus/${menuId}`).pipe(
      share()
    );

    subject$.subscribe((response) => {
      this.notifier.notify('success', this.translate.instant('pages.menu.messages.deletedMenu'));
    });

    return subject$;
  }

  public deleteChainMenu(chainId: number, menuId: number): Observable<{ message: string }> {
    const subject$ = this.http.delete<{ message: string }>(`/chains/${chainId}/menus/${menuId}`).pipe(
      share()
    );

    subject$.subscribe((response) => {
      this.notifier.notify('success', this.translate.instant('pages.menu.messages.deletedMenu'));
    });

    return subject$;
  }
}
