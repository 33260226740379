<div class="modal">
  <div class="close-link" *ngIf="!isUploading">
    <span class="close" mat-dialog-close></span>
  </div>
  <h1 mat-dialog-title>{{ 'pages.tables.modal.settings' | translate }}</h1>

  <form class="modal__form" novalidated [formGroup]="editTableForm">
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.tables.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name">
      <mat-error *ngIf="editTableForm.controls.name.touched && editTableForm.controls.name.invalid">
        <span *ngIf="editTableForm.controls.name.errors?.required">
          {{ 'pages.tables.modal.mandatoryField' | translate }}
        </span>
      </mat-error>
    </mat-form-field>


    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.tables.modal.notes' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="description">
    </mat-form-field>
    <div class="modal__buttons-wrapper">
      <div class="field bill-qr-upload" *ngIf="!isWaiter" (click)="$event.stopPropagation()">
        <mat-label class="label">{{ 'pages.tables.content.qr' | translate }}</mat-label>
        <app-form-file-upload
          appDragAndDropFiles
          [previewUrl] = "this.invoiceQRCodeUrl"
          id="qr-code-image-{{ tableData.id }}"
          formControlName="QRCodeFile"
          accept="image/jpg, image/png"
          (change)="onQrFileChange($event)"
        >
          {{ 'formFilUpload.dragAndDrop' | translate }}
        </app-form-file-upload>
      </div>
    </div>
    <div class="modal__buttons-wrapper">
      <button class="table-action"
              *ngIf="tableData.deviceUuid !== null"
              mat-stroked-button
              (click)="unlinkDevice()">
        {{ 'pages.tables.content.unlinkDevice' | translate }}
      </button>
      <button *ngIf="tableData.deviceUuid === null"
              type="button"
              mat-stroked-button
              (click)="linkDevice()">
        {{ 'pages.tables.content.linkDevice' | translate }}
      </button>

      <button class="table-action"
        *ngIf="tableData.online && tableData.deviceUuid !== null"
        mat-stroked-button
        color="warn"
        (click)="turnOffTables()">
        {{ 'pages.tables.actions.turnOff' | translate }}
      </button>
    </div>

    <mat-divider></mat-divider>

    <mat-dialog-actions>
      <button mat-stroked-button
              class="btn__warning"
              [disabled]="isUploading"
              (click)="deleteCurrentTable()"
      >{{ 'pages.tables.content.deleteTable' | translate }}</button>

      <button mat-stroked-button
              class="btn__active"
              [disabled]="!isFormChanged || editTableForm.invalid || isUploading"
              (click)="editCurrentTable()"
      >{{ 'pages.tables.content.save' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>
