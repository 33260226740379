import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public message!: string;
  public title!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string},
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
    this.message = this.data.message;
    this.title = this.data.title;
  }
}
