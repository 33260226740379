<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.chains.modal.addNewChain' | translate }}</h1>
  <form class="modal__form"
        [formGroup]="addChainForm"
        (submit)="addChain(addChainForm)"
  >
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.chains.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name">
      <mat-error *ngIf="addChainForm.controls.name.touched">
        <span *ngIf="addChainForm.controls.name.errors?.required">
          {{ 'pages.chains.modal.required' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.chains.modal.email' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="email">
      <mat-error *ngIf="addChainForm.controls.email.touched && addChainForm.controls.email.invalid">
        <span *ngIf="addChainForm.controls.email.errors?.required">
          {{ 'pages.chains.modal.required' | translate }}
        </span>
        <span *ngIf="addChainForm.controls.email.errors?.email">
          {{ 'pages.chains.modal.validEmail' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.chains.modal.password' | translate }}</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="addChainForm.controls.password.touched && addChainForm.controls.password.invalid">
        <span *ngIf="addChainForm.controls.password.errors?.required">
          {{ 'pages.chains.modal.required' | translate }}
        </span>
        <span *ngIf="addChainForm.controls.password.errors?.minlength">
          {{ 'pages.chains.modal.passwordMinLength' | translate }}
        </span>
        <span *ngIf="addChainForm.controls.password.errors?.maxlength">
          {{ 'pages.chains.modal.passwordMaxLength' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addChainForm.invalid">
        <ng-container *ngIf="loading; else createChain">
          <mat-spinner diameter="22" color="secondary"></mat-spinner>
        </ng-container>
        <ng-template #createChain>{{ 'pages.chains.modal.createChain' | translate }}</ng-template>
      </button>
    </div>
  </form>
</div>
