<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.specials.title' | translate }}</h1>
    <div class="actions">
      <button class="btn btn__primary btn__icon" *ngIf="isEdited" (click)="openAddSpecial()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square"
                stroke-linejoin="round"/>
        </svg>
        <span class="add">{{ 'pages.specials.actions.add' | translate }}</span>
      </button>
      <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}" (click)="edit()">
        <span *ngIf="!isEdited">{{ 'pages.specials.actions.manage' | translate }}</span>
        <span *ngIf="isEdited">{{ 'pages.specials.actions.done' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="content-wrap">

      <div class="empty" *ngIf="allSpecials?.length === 0 else content">
        <span>{{ 'pages.specials.empty' | translate }}</span>
      </div>

    <ng-template #content>
      <div class="specials-list"
           cdkDropList
           [cdkDropListDisabled]="!isEdited || isFiltered"
           (cdkDropListDropped)="dropSpecial($event)">
        <app-special
          [class.can_moved]="isEdited && !isFiltered"
          *ngFor="let special of allSpecials"
          cdkDrag
          cdkDragBoundary=".specials-list"
          [special]="special"
          [isEdit]="isEdited"
          [chainId]="currentChain.id"
          [allRestaurants]="allRestaurants"
        ></app-special>
      </div>
    </ng-template>


    <div class="filter-wrap">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.specials.status' | translate }}</mat-label>
        <mat-select [(ngModel)]="status">
          <mat-option value="active"> {{ 'pages.specials.active' | translate }}</mat-option>
          <mat-option value="inactive">{{ 'pages.specials.inactive' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ 'pages.specials.modal.start' | translate }}</mat-label>
        <input matInput [(ngModel)]="startDate" [matDatepickerFilter]="myFilterStart" [matDatepicker]="start">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
        <mat-datepicker #start></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'pages.specials.modal.end' | translate }}</mat-label>
        <input matInput [(ngModel)]="endDate" [matDatepickerFilter]="myFilterEnd" [matDatepicker]="end">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
        <mat-datepicker #end></mat-datepicker>
      </mat-form-field>


      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurants.title' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedRestaurantId" multiple>
          <mat-option *ngFor="let restaurant of allRestaurants" [value]="restaurant.id">
            {{ restaurant.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="filter-controls">
        <button class="btn btn__primary"
                (click)="applyFilters(startDate, endDate, status, selectedRestaurantId ? selectedRestaurantId : undefined)">{{ 'pages.specials.actions.apply' | translate }}
        </button>
        <button class="btn btn__secondary" (click)="resetFilters()">{{ 'pages.specials.actions.reset' | translate }}
        </button>
      </div>
    </div>
  </div>

</div>
