import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from '../services/admin/admin.service';
import { IRestaurantInfo } from '../interfaces/restaurant';

@Injectable({
  providedIn: 'root'
})
export class RestaurantResolver implements Resolve<IRestaurantInfo> {

  constructor(private adminService: AdminService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IRestaurantInfo> {
    return this.adminService.getRestaurantInfo((route.paramMap.get('id') ?? 0) as number);
  }
}
