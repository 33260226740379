<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.restaurantAccount.addIntegrationTitle' | translate }}</h1>

  <form class="modal__form" [formGroup]="addIntegration" (submit)="addNewIntegration(addIntegration)">
    <mat-form-field floatLabel="always" class="login-wrap__text">
      <mat-label>{{ 'pages.restaurantAccount.provider' | translate }}</mat-label>
      <mat-select placeholder="Choose provider" formControlName="provider">
        <mat-option value="VAST">
          VAST
        </mat-option>
        <mat-option value="vistarMedia">
          Vistar Media
        </mat-option>
        <mat-option value="kelseysMenu">
          Kelseys Menu
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container *ngIf="addIntegration.get('provider')?.value === 'VAST'">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVastTagUrl' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="vastTagUrl">
        <mat-error *ngIf="addIntegration.get('vastTagUrl')?.errors?.pattern && addIntegration.get('vastTagUrl')?.touched">
          {{ 'pages.restaurantAccount.urlValidation' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="addIntegration.get('provider')?.value === 'vistarMedia'">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVistarMediaApiKey' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="apiKey">
        <mat-error *ngIf="addIntegration.get('apiKey')?.errors && addIntegration.get('apiKey')?.touched">
          {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVistarMediaNetworkId' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="networkId">
        <mat-error *ngIf="addIntegration.get('networkId')?.errors && addIntegration.get('networkId')?.touched">
          {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVistarMediaVenueId' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="venueId">
        <mat-error *ngIf="addIntegration.get('venueId')?.errors && addIntegration.get('venueId')?.touched">
          {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="addIntegration.get('provider')?.value === 'kelseysMenu'">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.storeNumber' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="storeNumber">
        <mat-error *ngIf="addIntegration.get('storeNumber')?.errors && addIntegration.get('storeNumber')?.touched">
          {{ 'pages.restaurantAccount.maxLengthNumberValidation' | translate }}
        </mat-error>
      </mat-form-field>

    </ng-container>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" [disabled]="loading || addIntegration.invalid" type="submit">
        {{ 'pages.restaurantAccount.addIntegration' | translate }}
      </button>
    </div>
  </form>
</div>
