import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITable } from '../../../interfaces/table';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { TableService } from '../../../services/table/table.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { ConfirmationDialogComponent } from '../confirm-dialog/confirmation-dialog.component';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddNewWidgetCodeComponent } from '../add-new-widget-code/add-new-widget-code.component';
import {FileUploadService} from '../../../services/file-upload.service';


@UntilDestroy()
@Component({
  selector: 'app-edit-table',
  templateUrl: './edit-table.component.html',
  styleUrls: ['./edit-table.component.scss']
})
export class EditTableComponent implements OnInit {
  public editTableForm: FormGroup = this.generateForm();
  public tableData!: ITable;
  public isWaiter: boolean | undefined;
  public invoiceQRCodeUrl!: string;
  public qrFile: any;
  public tableId: number | undefined;
  private originalFormValue!: {name: string, description: string};
  public isUploading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { table: ITable },
    public dialogRef: MatDialogRef<any>,
    public tableService: TableService,
    private formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private restaurantService: RestaurantService,
    private translate: TranslateService,
    private notifier: NotifierService,
    private fileUpload: FileUploadService
  ) {}

  ngOnInit(): void {
    this.tableData = this.data.table;
    this.invoiceQRCodeUrl = this.tableData?.invoiceQRCodeUrl;
    this.initForm();
  }

  private initForm(): void {
    const { name, description } = this.tableData;

    this.editTableForm.patchValue({ name, description });
    this.originalFormValue = { name, description };
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: [this.tableData?.name, [Validators.required]],
      QRCodeFile: [null],
      description: [this.tableData?.description],
    });
  }

  get isFormChanged(): boolean {
    return this.originalFormValue.name !== this.editTableForm.value.name
      || this.originalFormValue.description !== this.editTableForm.value.description
      || this.qrFile;
  }

  editCurrentTable(): void {
    this.isUploading = true;
    this.dialogRef.disableClose = true;
    const isEdited = this.editTableForm.get('name')?.value === this.tableData.name
      && this.editTableForm.get('description')?.value === this.tableData.description;

    const table: {name: string, description: string, qrCodeFileId?: string} = {
      name: this.editTableForm.get('name')?.value,
      description: this.editTableForm.get('description')?.value,
    };

    if (isEdited && !this.qrFile) {
      return this.dialogRef.close();
    }

    if (this.qrFile) {
      this.fileUpload.upload(this.qrFile).pipe(
        switchMap(event => {
          table.qrCodeFileId = event.intent.fileId.toString();
          return this.restaurantService.editTable(
            this.tableData?.restaurantId,
            this.tableData?.id,
            table
          );
        })
      ).subscribe((data) => {
        this.isUploading = false;
        this.dialogRef.close(data);
      });
    } else {
      this.restaurantService.editTable(
        this.tableData?.restaurantId,
        this.tableData?.id,
        table
      ).subscribe((data) => {
        this.isUploading = false;
        this.dialogRef.close(data);
      });
    }
  }

  onQrFileChange($event: Event): void {
    const file = ($event.target as HTMLInputElement).files;
    if (file) {
      this.qrFile = file[0];
      this.editTableForm.patchValue({ QRCodeFile: file[0] });
    }
  }

  removeTable(restaurantId: number, tableId: number): void {
    this.tableService.remove(restaurantId, tableId).pipe(
      untilDestroyed(this)
    ).subscribe();
  }

  deleteCurrentTable(): void {
    const title = this.translate.instant('pages.tables.modal.delete');
    const message = this.translate.instant('pages.tables.modal.deleteTable');
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
      tap(() => {
        this.removeTable(this.tableData.restaurantId, this.tableData.id);
      }),
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }

  unlinkDevice(): void {
    const title = this.translate.instant('pages.tables.modal.unlink');
    const message = this.translate.instant('pages.tables.modal.unlinkDevice');
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
      switchMap((response) => this.restaurantService.unlinkDevice(this.tableData.restaurantId, this.tableData.id)),
    ).subscribe((resp) => {
      this.tableData.deviceUuid = resp.deviceUuid;
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.unlinkTable'));
    });
  }

  turnOffTables(): void {
    const title = this.translate.instant('pages.tables.modal.turnOff');
    const message = this.translate.instant('pages.tables.modal.turnOffDevice');
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.tableService.requestDeviceTurnOff(this.tableData);
    });
  }

  linkDevice(): void {
    const newWidgetCodeModal = this.matDialog.open(AddNewWidgetCodeComponent, {
      data: {
        table: this.tableData
      },
      panelClass: 'confirmation-modal'
    });

    newWidgetCodeModal.afterClosed().pipe(
      filter(d => !!d),
      switchMap((response) => {
        return this.restaurantService.linkNewDeviceToTable(response.restaurantId, response.tableId, response.widgetCode);
      })
    ).subscribe(table => {
      this.tableData.deviceUuid = table.deviceUuid;
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.linkTable'));
    });
  }
}
