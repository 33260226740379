import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { WebsocketService } from '../../../services/websocket/websocket.service';
import { ConnectionStatusService } from '../../../services/connection-status.service';

@Component({
  selector: 'app-login',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss']
})
export class AccountLayoutComponent {

  constructor(
    private translate: TranslateService,
    private notifier: NotifierService,
    private websocket: WebsocketService,
    private connectionStatus: ConnectionStatusService,
  ) {}

  get isOnline(): boolean {
    return this.connectionStatus.online$.getValue();
  }

  get isWSConnected(): boolean {
    return this.websocket.connected$.getValue();
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public handlerChangeLanguage(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      this.translate.use(event.target.value);
      localStorage.setItem('lang', event.target.value);
    }
  }

}
