import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('s3.amazonaws.com')) {
      return next.handle(request);
    }

    const token = this.auth.getToken();

    request = request.clone({
      url: this.setUrl(request.url),
    });

    if (token) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`,
        }
      });
    }

    return next.handle(request);
  }

  private setUrl(url: string): string {
    if (url.startsWith('http')) {
      return url;
    }

    if (url.includes('/assets/locale/')) {
      return url;
    }

    return `${environment.apiUrl}${url}`;
  }

}
