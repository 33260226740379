import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { AddTableComponent } from '../../components/modals/add-table/add-table.component';
import { filter, pluck, tap } from 'rxjs/operators';
import { TableService } from '../../services/table/table.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '../../services/auth/auth.service';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from '../../components/modals/confirm-dialog/confirmation-dialog.component';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { IFeature, IRestaurantInfo } from '../../interfaces/restaurant';
import { CallWaiteConfigComponent } from '../../components/modals/call-waite-config/call-waite-config.component';
import { AdminService } from '../../services/admin/admin.service';

@UntilDestroy()
@Component({
  selector: 'app-tables-page',
  templateUrl: './tables-page.component.html',
  styleUrls: ['./tables-page.component.scss'],
})
export class TablesPageComponent implements OnInit {

  public isEdited = false;
  public editable = false;
  public isWaiter: boolean | undefined;
  public restaurant!: IRestaurantInfo;
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public selectedTables = new Set<number>();
  public isSelectAllTables = false;
  public callWaiteConfig!: IFeature;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private location: Location,
    private dialog: ModalService,
    private notifier: NotifierService,
    private translate: TranslateService,
    private websocketService: WebsocketService,
    private adminService: AdminService,
    public tableService: TableService,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.restaurant = restaurant;
        this.editable = this.auth.isAdmin(restaurant);
        this.isWaiter = this.auth.isWaiter(restaurant);
        this.callWaiteConfig = restaurant.features;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  addTable(): void {
    const addTable = this.matDialog.open(AddTableComponent, {
      data: {
        restaurantId: this.restaurant.id
      },
    });

    addTable.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.addTable'));
    });
  }

  turnOffTables(): void {
    const title = this.translate.instant('pages.tables.modal.turnOff');
    const message = this.translate.instant('pages.tables.modal.turnOffDevices');
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });
    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe(() => {
      this.tableService.items.filter(table => this.selectedTables.has(table.id)).forEach(table => {
        this.tableService.requestDeviceTurnOff(table);
      });
    });
  }

  changeSelectedTables({event, id}: {event: boolean, id: number}): void {
    switch (event) {
      case true:
        this.selectedTables.add(id);
        break;
      case false:
        this.selectedTables.delete(id);
        break;
    }
  }

  public clearSelectedTables(): void {
    this.selectedTables.clear();
  }

  public isEditedToggle(): void {
    if (this.isEdited) { this.clearSelectedTables(); }
    this.isEdited = !this.isEdited;
    this.isSelectAllTables = false;
  }

  public selectAllTablesToggle(): void {
    if (this.isSelectAllTables) {
      this.selectedTables.clear();
      this.isSelectAllTables = false;
    } else {
      const onlineTables = this.tableService.items.filter(table => table.online === true);
      const onlineTablesIds = onlineTables.map(table => table.id);
      onlineTablesIds.forEach(id => this.selectedTables.add(id));
      this.isSelectAllTables = true;
    }
  }

  callWaiterConfig(): void {
    const callWaiterConfig = this.matDialog.open(CallWaiteConfigComponent, {
      data: {
        restaurantId: this.restaurant.id,
      },
    });

    callWaiterConfig.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((data) => {
      const info = data as Partial<IRestaurantInfo>;

      this.callWaiteConfig = info.features ? info.features : this.callWaiteConfig;
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.callWaiterConfig'));
    });
  }
}
