import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WidgetUIConfiguration } from '../interfaces/widget-ui-config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChainService {

  constructor(
    private readonly http: HttpClient
  ) {}

  public getWidgetUiConfig(id: number): Observable<WidgetUIConfiguration> {
    return this.http.get<WidgetUIConfiguration>(`/chains/${id}/widget-ui-config`);
  }

  public updateWidgetUiConfig(
    id: number,
    config: WidgetUIConfiguration | null,
    files: { [key: string]: File } | null
  ): Observable<WidgetUIConfiguration> {
    const formData = new FormData();
    formData.set('config', JSON.stringify(config));

    if (files) {
      Object.keys(files).forEach((key) => {
        formData.set(key, files[key]);
      });
    }

    return this.http.post<WidgetUIConfiguration>(`/chains/${id}/widget-ui-config`, formData);
  }

}
