import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IEditTable, ITable } from '../../interfaces/table';
import {AnyIntegration, AnyIntegrationNew, IDeviceSchedule, IFeature} from '../../interfaces/restaurant';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  private env = environment;

  constructor(
    private http: HttpClient
  ) {
  }

  public addTable(id: number, name: string, widgetCode: string, description?: string, qrCodeFileId?: string,  ): Observable<ITable> {
    return this.http.post<ITable>(`/restaurants/${id}/tables`, {name, widgetCode, description, qrCodeFileId});
  }

  public linkNewDeviceToTable(restaurantId: number, tableId: number, widgetCode: string): Observable<ITable> {
    return this.http.post<ITable>(`/restaurants/${restaurantId}/tables/${tableId}/link-device`, { widgetCode });
  }

  public unlinkDevice(restaurantId: number, tableId: number): Observable<ITable> {
    return this.http.post<ITable>(`/restaurants/${restaurantId}/tables/${tableId}/unlink-device`, {});
  }

  public editTable(restaurantId: number, tableId: number, table: {
    name?: string,
    qrCodeFileId?: string,
    description?: string
  }): Observable<ITable> {
    return this.http.patch<ITable>(`/restaurants/${restaurantId}/tables/${tableId}`, table)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      );
  }

  public getQrCode(file: any, tableId: number, restaurantId: number): Observable<any> {
    const dataQr: FormData = new FormData();
    dataQr.append('QRCodeImage', file, file.name);
    return this.http.patch(`/restaurants/${restaurantId}/tables/${tableId}`, dataQr);
  }

  public addWaiter({id, name, email, password, tableIds, role = 'WAITER'}:
                     { id: string, name: string, email: string, password: string, tableIds?: string[],  role?: string }): Observable<any> {
    return this.http.post(`/restaurants/${id}/staff`, {name, email, password, tableIds,  role});
  }

  public getAllTables(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/tables`)
      .pipe(
        catchError((err) => {
          throw err;
          return [];
        }),
        tap((tables) => {
        // const tableId
      }));
  }

  public getAllWaiters(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/staff/waiters/`);
  }

  public removeWaiter(restaurantId: number, staffId: number): Observable<void> {
    return this.http.delete<void>(`/restaurants/${restaurantId}/staff/${staffId}`);
  }

  public getWaiterById(id: number, staffId: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/staff/${staffId}`);
  }

  public editWaiter({id, name, email, password, tableIdsToAttach, tableIdsToDetach, idStaff, role = 'WAITER'}: IEditTable): Observable<any>{
    return this.http.patch(`/restaurants/${id}/staff`, {
      id: idStaff,
      name,
      email,
      password,
      tableIdsToAttach,
      tableIdsToDetach,
      role
    });
  }

  public addIntegration(id: number, {provider, credentials}: AnyIntegrationNew): Observable<any> {
     return this.http.post(`/restaurants/${id}/integrations`, {provider, credentials});
  }

  public getIntegrationById(id: number, integrationId: string): Observable<any> {
    return this.http.get(`/restaurants/${id}/integrations/${integrationId}`);
  }

  public getAllIntegration(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/integrations`);
  }

  public editIntegration(restaurantId: number, {id, provider, credentials}: AnyIntegration): Observable<any> {
    return this.http.patch(`/restaurants/${restaurantId}/integrations/${id}`, {
      provider, credentials
    });
  }
  public removeIntegration(id: number, integrationId: string): Observable<void>{
    return this.http.delete<void>(`/restaurants/${id}/integrations/${integrationId}`);
  }

  public configureCollWaiter(restaurantId: number, {
    withCallWaiter,
    withCallWaiterToPay,
    withCallWaiterToRepeat
  }: IFeature
  ): Observable<any> {
    return this.http.patch(`/restaurants/${restaurantId}`, {withCallWaiter, withCallWaiterToPay, withCallWaiterToRepeat});
  }

  public uploadPdf(file: any, id: number): Observable<any> {
    const testData: FormData = new FormData();
    testData.append('menu', file, file.name);
    return this.http.post(`/restaurants/menu/upload/${id}`, testData, {
      reportProgress: true
    });
  }

  public uploadHappyHoursData({id, happyHours}: {id: number, happyHours: object[]}): Observable<any> {
    return this.http.patch(`/restaurants/${id}`, {happyHours});
  }

  public uploadWorkingHours({id, workSchedule}: {id: number, workSchedule: object[]}): Observable<IDeviceSchedule[]> {
    return this.http.post<IDeviceSchedule[]>(`/restaurants/${id}/device-work-schedule`, {workSchedule});
  }

  public getWorkingHours(id: number): Observable<IDeviceSchedule[]> {
    return this.http.get<IDeviceSchedule[]>(`/restaurants/${id}/device-work-schedule`);
  }

  public uploadHappyHoursPdf(file: any, id: number): Observable<any> {
    const data: FormData = new FormData();
    data.append('menu', file, file.name);
    return this.http.post(`/restaurants/happyMenu/upload/${id}`, data);
  }

  public updateClickableMediaConfig(id: number, items: any[]): Observable<any> {
    return this.http.patch(`/restaurants/${id}`, {
      clickableMediaConfig: JSON.stringify(items),
    });
  }

  public getClickableMediaConfig(id: number): Observable<any> {
    return this.http.get(`/restaurants/${id}/clickableMediaConfig`);
  }

}
