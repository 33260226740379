import { Component, OnInit } from '@angular/core';
import { AddRestaurantComponent } from '../../components/modals/add-restaurant/add-restaurant.component';
import { AdminService } from '../../services/admin/admin.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, pluck, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Chain } from '../../interfaces/chain';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-super-admin',
  templateUrl: './restaurants-page.component.html',
  styleUrls: ['./restaurants-page.component.scss']
})
export class RestaurantsPageComponent implements OnInit {

  public allRestaurants!: any;
  public chainId!: number;
  public currentChain!: Chain;
  public isEdited = false;

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private adminService: AdminService,
    private notifier: NotifierService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getCurrentChain();
  }

  private getCurrentChain(): void {
    this.activateRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.currentChain = chain;
      }),
      switchMap(chain => this.adminService.getRestaurantsByChainId(chain.id)),
      untilDestroyed(this),
    ).subscribe((restaurants) => this.allRestaurants = restaurants.reverse());
  }

  get isEmpty(): boolean {
    if (this.allRestaurants) {
      return this.allRestaurants.length > 0;
    }
    return true;
  }

  public openAddRestaurant(): void {
    const restaurantModal = this.matDialog.open(AddRestaurantComponent, {
      data: {
        chainId: this.currentChain.id
      }
    });

    restaurantModal.afterClosed().pipe(
      filter(Boolean)
    ).subscribe((data) => {
      this.notifier.notify('success', this.translate.instant('pages.restaurants.addRestaurantSuccessfully'));
      this.allRestaurants = [data, ...this.allRestaurants];
    });
  }
}
