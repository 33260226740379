<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.tables.modal.title' | translate }}</h1>
  <form class="modal__form" novalidated [formGroup]="addTable" (submit)="addNewTable(addTable)">
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.tables.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name">
      <mat-error *ngIf="addTable.controls.name.touched">
        <span *ngIf="addTable.controls.name.errors?.required">
          {{ 'pages.tables.modal.mandatoryField' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.tables.modal.code' | translate }}</mat-label>
      <input type="text" matInput appFormInputTrim formControlName="widgetCode">
      <mat-error *ngIf="addTable.controls.widgetCode.touched">
        <span *ngIf="addTable.controls.widgetCode.errors?.required">
          {{ 'pages.tables.modal.mandatoryField' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.tables.modal.notes' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="description">
    </mat-form-field>

    <div class="login-wrap__text">
      <mat-label >{{ 'pages.tables.content.qr' | translate }}</mat-label>
      <app-form-file-upload
        appDragAndDropFiles
        formControlName="QRCodeFile"
        accept="image/jpg, image/png"
        (change)="onQrFileChange($event)"
      >
        {{ 'formFilUpload.dragAndDrop' | translate }}
      </app-form-file-upload>
    </div>
    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="addTable.invalid || isUploading">
        {{ 'pages.tables.modal.create' | translate }}
      </button>
    </div>
  </form>
</div>
