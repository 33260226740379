import { Component, Input } from '@angular/core';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { ISpecial } from '../../../interfaces/special';
import { SpecialsStoreService } from '../../../services/specials/specials-store.service';
import { MatDialog } from '@angular/material/dialog';
import { EditSpecialComponent } from '../../modals/edit-special/edit-special.component';
import {ConfirmationDialogComponent} from '../../modals/confirm-dialog/confirmation-dialog.component';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.scss']
})
export class SpecialComponent {
  public isEdited = false;
  public isUpdating = false;
  public specialData!: ISpecial;
  public restaurants!: IRestaurantInfo[];
  public start = '';
  public end = '';
  public isActive!: boolean;

  @Input() chainId!: number;
  @Input() allRestaurants: IRestaurantInfo[] = [];
  @Input() set isEdit(value: boolean) {
    this.isEdited = value;
  }
  @Input() set special(special: ISpecial) {
    this.specialData = special;
    const scheduleStart = new Date(this.specialData.scheduleStart);
    const scheduleEnd = new Date(this.specialData.scheduleEnd);
    const today = new Date();

    this.start = scheduleStart.toLocaleDateString('en-US', {
      month: '2-digit', // MM
      day: '2-digit',   // DD
      year: 'numeric'   // YYYY
    });
    this.end = scheduleEnd.toLocaleDateString('en-US', {
      month: '2-digit', // MM
      day: '2-digit',   // DD
      year: 'numeric'   // YYYY
    });
    this.isActive = today >= scheduleStart && today <= scheduleEnd;
  }

  constructor(
    private specialsStore: SpecialsStoreService,
    private translate: TranslateService,
    private matDialog: MatDialog
  ) {}

  removeSpecial(): void {
    const title = this.translate.instant('pages.specials.modal.deleteSpecialsConfirmTitle');
    const message = this.translate.instant('pages.specials.modal.deleteSpecialsConfirm');

    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.specialsStore.deleteSpecial(this.chainId, this.specialData.id);
    });
  }

  public openEditSpecial(): void {
    this.matDialog.open(EditSpecialComponent, {
      data: {
        chainId: this.chainId,
        allRestaurants: this.allRestaurants,
        special: this.specialData
      }
    });
  }
}
