<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.tables.modal.titleCallWaiter' | translate }}</h1>
  <form class="modal__form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-checkbox
      formControlName="allOn"
      color="primary"
      class="parentCheckbox"
      [indeterminate]="someOn()"
    >
      {{ 'pages.tables.modal.selectAll' | translate }}
    </mat-checkbox>
    <div class="checkboxBox">
      <mat-checkbox
        formControlName="withCallWaiter"
        color="primary"
        class="checkbox"
        [class.active]="this.form.value.withCallWaiter"
        [ngClass]="{'active': this.form.value.withCallWaiter, 'inactive': !this.form.value.withCallWaiter}"
      >
        {{ 'pages.tables.modal.callWaiter' | translate }}
      </mat-checkbox>
      <mat-checkbox
        formControlName="withCallWaiterToRepeat"
        color="primary"
        class="checkbox"
        [ngClass]="{'active': this.form.value.withCallWaiterToRepeat, 'inactive': !this.form.value.withCallWaiterToRepeat}"
      >
        {{ 'pages.tables.modal.anotherRound' | translate }}
      </mat-checkbox>
      <mat-checkbox
        formControlName="withCallWaiterToPay"
        color="primary"
        class="checkbox"
        [ngClass]="{'active': this.form.value.withCallWaiterToPay, 'inactive': !this.form.value.withCallWaiterToPay}"
      >
        {{ 'pages.tables.modal.requestBill' | translate }}
      </mat-checkbox>
    </div>
    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="!form.dirty || !form.valid">
        {{ 'pages.tables.modal.save' | translate }}
      </button>
    </div>
  </form>
</div>
