import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFormInputTrim]',
})
export class FormInputTrimDirective {

  constructor(
    private readonly element: ElementRef
  ) { }

  @HostListener('blur', ['$event'])
  public onBlur(): void {
    const element = this.element.nativeElement;
    const value = element.value;
    console.log('value', value);

    if (typeof value === 'string') {
      element.value = value.trim();
    }

    if (element.value !== value) {
      element.dispatchEvent(new Event('input'));
    }
  }

}
