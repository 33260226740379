import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import {AuthService} from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private notifier: NotifierService,
    private authService: AuthService
  ) {}

  get language(): string {
    return localStorage.getItem('lang') ?? 'en-CA';
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((response: HttpErrorResponse) => {
      const notification = (error: any) => {
        if (error.translation && error.translation[this.language]) {
          this.notifier.notify('error', error.translation[this.language]);
        } else if (error.message) {
          this.notifier.notify('error', error.message);
        }
      };

      if (response) {
        switch (response.status) {
          case 401: {
            this.Error401Handler();
            break;
          }
        }
      }

      if (response.error?.violations && response.error?.violations?.length > 0) {
        response.error.violations.map((violation: any) => notification(violation));
      } else if (response.error.message) {
        notification(response.error);
      } else {
        this.notifier.notify('error', this.language === 'en-CA' ? 'Service is unavailable' : 'Le service est indisponible');
      }

      return throwError(response);
    }));
  }

  Error401Handler(): void {
    this.authService.logout();
    console.log('401');
  }

}
