import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, fromEvent, Observable} from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

type IntercomMethod = 'init' | 'push_notification.get_token';
type IntercomMethodData = any;

export interface IIntercomEventMessage {
  type: 'oongalee-events';
  version: string;
  event: string;
  data: any;
}

export interface IIntercomMethodMessage {
  type: 'oongalee-events';
  version: string;
  method: IntercomMethod;
  data: IntercomMethodData;
}

@Injectable({
  providedIn: 'root'
})
export class OongaleeIntercom {

  private port$ = new BehaviorSubject<MessagePort | null>(null);

  public messages$ = new Subject<IIntercomEventMessage>();

  get connected(): boolean {
    return this.port$.getValue() !== null;
  }

  private static debug(...params: any): void {
    if (!environment.production) {
      console.log('Oongalee Intercom - ', ...params);
    }
  }

  private static methodMessage(method: IntercomMethod, data: IntercomMethodData = null): IIntercomMethodMessage {
    return { type: 'oongalee-events', version: '0.0.1', method, data};
  }

  init(): void {
    OongaleeIntercom.debug('Init');

    fromEvent<MessageEvent>(window, 'message').pipe(
      filter((event) => {
        return event.data === 'oongalee-handshake' && typeof event.ports !== undefined && typeof event.ports[0] !== undefined;
      }),
      tap((event) => OongaleeIntercom.debug('Handshake', JSON.stringify(event))),
      map((event) => event.ports[0]),
      tap((port) => this.port$.next(port)),
      switchMap((port) => {
        port.start();
        this.call('init');

        return fromEvent<MessageEvent>(port, 'message');
      }),
      tap((event) => OongaleeIntercom.debug('Received raw:', event)),
      map((event) => {
        OongaleeIntercom.debug('Message type:', typeof event.data);

        if (typeof event.data === 'string') {
          const data = JSON.parse(event.data.replace('\/', ''));

          OongaleeIntercom.debug('Message parse:', data);

          return data;
        }

        return event.data;
      }),
      filter((message) => {
        return message.type && message.version && message.event;
      }),
    ).subscribe((message) => {
      OongaleeIntercom.debug('Received:', message);

      this.messages$.next(message);
    });
  }

  call(method: IntercomMethod, data: IntercomMethodData = null): boolean {
    const port = this.port$.getValue();
    if (!port) {
      return false;
    }

    const message = OongaleeIntercom.methodMessage(method, data);
    port.postMessage(JSON.stringify(message));
    OongaleeIntercom.debug('Sent:', message);

    return true;
  }

}
