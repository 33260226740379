import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing as SentryBrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';


if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.name,
  integrations: [
    new SentryBrowserTracing({
      tracingOrigins: [environment.apiUrl],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
