import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { forkJoin, NEVER, Observable, of } from 'rxjs';
import { Chain } from '../interfaces/chain';
import { AdminService } from '../services/admin/admin.service';
import { IRestaurantInfo } from '../interfaces/restaurant';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChainsResolver implements Resolve<Chain[]> {
  private chains!: Chain[];
  private restaurants!: IRestaurantInfo[];

  constructor(
    private router: Router,
    private adminService: AdminService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Chain[]> {
    return forkJoin([this.adminService.getAllChains(), this.adminService.getAllRestaurants()]).pipe(
      switchMap(([chains, restaurants]) => {
        if (chains.length > 1) {
          return of(chains);
        } else if (chains.length === 1) {
          const chainId = chains[0]?.id;
          this.router.navigate(['chains', chainId, 'restaurants']);
          return NEVER; // return an observable that never emits
        } else {
          const restaurantId = restaurants[0]?.id;
          this.router.navigate(['restaurants', restaurantId, 'tables']);
          return NEVER; // return an observable that never emits
        }
      })
    );
  }
}
