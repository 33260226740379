import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../../services/admin/admin.service';
import { Chain } from '../../../interfaces/chain';
import { AuthService } from '../../../services/auth/auth.service';
import { ConfirmationDialogComponent } from '../../../components/modals/confirm-dialog/confirmation-dialog.component';
import { filter, pluck, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { passwordFormValidator } from '../../../shared/custom-validators';

@UntilDestroy()
@Component({
  selector: 'app-chain-settings-page',
  templateUrl: './chain-settings-page.component.html',
  styleUrls: ['./chain-settings-page.component.scss']
})
export class ChainSettingsPageComponent implements OnInit {

  public isChangePasswordEnabled = false;
  public isAvailable = false;
  public loading = false;
  public currentChain!: Chain;
  public chainInfo: FormGroup = this.formBuilder.group({
    name: new FormControl (null, [Validators.required]),
    email: new FormControl (null ),
    oldPassword: new FormControl(null, [Validators.minLength(4), Validators.maxLength(30)]),
    newPassword: new FormControl(null, [Validators.minLength(4), Validators.maxLength(30)]),
  }, { validators: [passwordFormValidator( 'newPassword', 'oldPassword', this.isChangePasswordEnabled)] });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private matDialog: MatDialog,
    private authService: AuthService,
    private notifier: NotifierService,
    public adminService: AdminService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('chain'),
      tap(chain => {
        this.currentChain = chain;
        this.getChain(chain.id);
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  public getChain(chainId: number): void {
    this.adminService.getChainById(chainId).subscribe((chain) => {
      this.currentChain = chain;
      this.getChainInfoToForm(chain);
    });
  }

  private getChainInfoToForm(chain: Chain): void {
    this.chainInfo.reset({
      name: chain.name,
      email: chain.email,
    });
  }

  public updateChainInfo(chainInfo: FormGroup): void {
    this.loading = true;

    let updatedChainInfo: any = {};

    if (chainInfo.get('name')?.valueChanges) {
      updatedChainInfo.name = chainInfo.value.name;
    }

    if (this.isChangePasswordEnabled && chainInfo.get('oldPassword')?.dirty && chainInfo.get('newPassword')?.dirty) {
      updatedChainInfo = {
        ...updatedChainInfo,
        oldPassword: chainInfo.value.oldPassword,
        password: chainInfo.value.newPassword,
      };
    }

    if (Object.keys(updatedChainInfo).length) {
      this.adminService.updateUser(this.currentChain.id, updatedChainInfo).pipe(
        tap(() => this.loading = false)
      ).subscribe(
        (updatedUser) => {
          this.currentChain = updatedUser;
          this.getChainInfoToForm(updatedUser);
          this.notifier.notify('success', this.translate.instant('pages.chains.messages.chainEdited'));

          this.loading = false;
          this.chainInfo.get('oldPassword')?.reset();
          this.chainInfo.get('newPassword')?.reset();
          this.chainInfo.markAsPristine();
          this.isChangePasswordEnabled = false;
        },
        () => {
          this.loading = false;
          this.chainInfo.get('oldPassword')?.reset();
          this.chainInfo.get('newPassword')?.reset();
          this.chainInfo.markAsPristine();
          this.isChangePasswordEnabled = false;
        });
    }
  }

  get isPasswordChanged(): boolean {
    if (this.isChangePasswordEnabled) {
      return (this.chainInfo.get('oldPassword')?.value?.length > 0 && this.chainInfo.get('newPassword')?.value?.length > 0);
    }
    return true;
  }

  public confirmToRemoveChainAdmin(): void {
    const title = this.translate.instant('pages.chains.modal.delete');
    const message = this.translate.instant('pages.chains.modal.deleteChain');
    const confirmationModal = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        message,
        title
      },
      panelClass: 'confirmation-modal'
    });

    // @ts-ignore
    confirmationModal.afterClosed().pipe(
      filter(Boolean),
    ).subscribe((): void => {
      this.removeChain(this.currentChain.id);
    });
  }

  private removeChain(chainId: number): void {
    this.adminService.deleteChain(chainId).subscribe(() => {

      const successMessage = `${this.translate.instant('pages.chains.messages.removedSuccessfullyPart1')} '${this.currentChain.name}' ${this.translate.instant('pages.chains.messages.removedSuccessfullyPart2')}`;
      this.notifier.notify('success', successMessage);

      this.router.navigate(['chains']);
    });
  }

  public enableChangingPasswordToggle(): void {
    this.isChangePasswordEnabled = !this.isChangePasswordEnabled;
    this.chainInfo.get('oldPassword')?.reset('');
    this.chainInfo.get('oldPassword')?.markAsPristine();
    this.chainInfo.get('newPassword')?.reset('');
    this.chainInfo.get('newPassword')?.markAsPristine();

    this.chainInfo.setValidators([passwordFormValidator( 'newPassword', 'oldPassword', this.isChangePasswordEnabled)]);
    this.chainInfo.updateValueAndValidity();
  }
}
