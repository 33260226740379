import { Component, OnInit } from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute } from '@angular/router';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { TableService } from '../../services/table/table.service';
import { MenuStoreService } from '../../services/menu/menu-store.service';
import {IClickableMediaItem, IRestaurantInfo} from '../../interfaces/restaurant';
import { IMenu } from '../../interfaces/menu';
import { interval } from 'rxjs';
import {distinctUntilChanged, map, pluck, take, tap} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-menu-page',
  templateUrl: './special-offer-page.component.html',
  styleUrls: ['./special-offer-page.component.scss'],
})
export class SpecialOfferPageComponent implements OnInit {

  public isEdit = false;
  public isLoading = true;

  public restaurant!: IRestaurantInfo;
  public restaurantId: number;
  public items: IClickableMediaItem[] = [];
  public menus: IMenu[] = [];

  constructor(
    private notify: NotifierService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private restaurantService: RestaurantService,
    private tableService: TableService,
    private menuStore: MenuStoreService,
  ) {
    this.restaurantId = this.activatedRoute.parent?.snapshot.params.id as number;
  }

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.restaurant = restaurant;

        this.menuStore.fetchMenus({ restaurantId: restaurant.id });

        this.restaurantService.getClickableMediaConfig(restaurant.id).subscribe((items) => {
          this.items = items ?? [];
          this.isLoading = false;
        });
      }),
      untilDestroyed(this),
    ).subscribe();



    this.menuStore.getMenus$.subscribe((menus) => {
      this.menus = menus;
    });


  }

  requestPlaylistItem(): void {
    const table = this.tableService.items.find((item) => item.online);

    if (table) {
      this.isLoading = true;
      table.playlistItems = undefined;
      this.tableService.requestPlaylistItems(table);

      const s$ = interval(1000).pipe(
        map(() => this.tableService.findById(table.id)?.playlistItems),
        take(10),
        distinctUntilChanged()
      ).subscribe((items) => {
        if (items) {
          this.items = items.map((item: IClickableMediaItem) => {
            item.menuId = this.items.find((i) => i.id === item.id)?.menuId;
            return item;
          });

          table.playlistItems = undefined;
        } else {
          this.notify.notify('error', this.translate.instant('pages.menu.specialOffer.dataNotReceived'));
        }

        this.isLoading = false;
        s$.unsubscribe();
      });
    } else {
      this.notify.notify('error', this.translate.instant('pages.menu.specialOffer.devicesOffline'));
    }
  }

  save(): void {
    this.restaurantService.updateClickableMediaConfig(
      this.restaurantId,
      this.items
    ).subscribe(() => {
      this.notify.notify('success', this.translate.instant('pages.menu.specialOffer.updSuccess'));
    });
  }

}
