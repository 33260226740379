import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OongaleeIntercom } from './services/oongalee-intercom';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private translate: TranslateService,
    private oongaleeIntercom: OongaleeIntercom,
  ) {
    this.oongaleeIntercom.init();

    translate.addLangs(['en-CA', 'fr-CA']);
    translate.setDefaultLang('en-CA');

    const lang = localStorage.getItem('lang') || translate.defaultLang;
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', lang);
    }

    translate.use(lang);
  }

}
