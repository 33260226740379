<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__tables__title">{{ waiterName + ' ' +  ('pages.waiters.table.modal.title' | translate) }}</h1>
  <ol class="modal__tables__list">
  <li *ngFor="let table of tables;">
    {{ table.name }}
  </li>
</ol>
</div>
