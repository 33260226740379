<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.waiters.modal.title' | translate }}</h1>

  <form class="modal__form" [formGroup]="addWaiter" (submit)="addNewWaiter(addWaiter)">
    <div class="modal__subtitle">
      <h2 class="hr">{{ 'pages.waiters.modal.info' | translate }}</h2>
    </div>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name">
      <mat-error *ngIf="addWaiter.controls.name.touched">
        <span *ngIf="addWaiter.controls.name.errors?.required">
          {{ 'pages.waiters.modal.required' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.email' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="email">
      <mat-error *ngIf="addWaiter.controls.email.touched && addWaiter.controls.email.invalid">
        <span *ngIf="addWaiter.controls.email.errors?.required">
          {{ 'pages.waiters.modal.required' | translate }}
        </span>
        <span *ngIf="addWaiter.controls.email.errors?.email">
          {{ 'pages.waiters.modal.validEmail' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.password' | translate }}</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="addWaiter.controls.password.touched && addWaiter.controls.password.invalid">
        <span *ngIf="addWaiter.controls.password.errors?.required">
          {{ 'pages.waiters.modal.required' | translate }}
        </span>
        <span *ngIf="addWaiter.controls.password.errors?.maxlength">
          {{ 'pages.waiters.modal.maxlength' | translate }}
        </span>
        <span *ngIf="addWaiter.controls.password.errors?.minlength">
          {{ 'pages.waiters.modal.minlength' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <div class="modal__subtitle">
      <h2 class="hr">{{ 'pages.waiters.modal.subtitle' | translate }}</h2>
    </div>

    <mat-form-field floatLabel="always" class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.tables' | translate }}</mat-label>
      <mat-select multiple placeholder="{{ 'pages.waiters.modal.selectTables' | translate }}" formControlName="tables">
        <mat-option *ngFor="let table of tables; let i =index" [value]="table.id">
          {{table.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" [disabled]="loading || addWaiter.invalid" type="submit">
        {{ 'pages.waiters.modal.createWaiter' | translate }}
      </button>
    </div>
  </form>
</div>
