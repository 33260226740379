<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.tables.title' | translate }}</h1>

    <div class="actions skeleton-loader" *ngIf="editable && tableService.loading">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>

    <div class="actions" *ngIf="(editable || isWaiter) && !tableService.loading">
<!--      <button class="btn btn__primary btn__icon" (click)="selectAllTablesToggle()" *ngIf="isEdited">-->
<!--        Select all tables-->
<!--      </button>-->
      <div class="checkbox-wrapper" *ngIf="isEdited">
        <input id="selectAllTables" type="checkbox" (click)="selectAllTablesToggle()">
        <label for="selectAllTables">{{ 'pages.tables.actions.selectAllTables' | translate }}</label>
      </div>
      <mat-divider [vertical]="true" *ngIf="isEdited"></mat-divider>
      <button class="btn btn__primary btn__icon" (click)="turnOffTables()" *ngIf="isEdited && selectedTables.size">
        {{ 'pages.tables.actions.turnOff' | translate }}
      </button>
      <button class="btn btn__primary btn__icon" (click)="addTable()" *ngIf="editable && isEdited && !selectedTables.size">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
        <span class="add">{{ 'pages.tables.actions.add' | translate }}</span>
      </button>
      <button class="btn btn__primary btn__icon" (click)="callWaiterConfig()" *ngIf="editable && isEdited && !selectedTables.size">
        <span class="add">{{ 'pages.tables.actions.onOffCallWaiter' | translate }}</span>
      </button>
      <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}" (click)="isEditedToggle()">
        <span *ngIf="!isEdited">{{ 'pages.tables.actions.manage' | translate }}</span>
        <span *ngIf="isEdited">{{ 'pages.tables.actions.done' | translate }}</span>
      </button>
    </div>
  </div>

  <app-tables [isEdited]="isEdited"
              [selectedTables]="selectedTables"
              (selectedTablesChange)="changeSelectedTables($event)"
  ></app-tables>
</div>
