import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  constructor(private dialog: MatDialog) {
  }

  public openDialog<T, R = any>(modal: any): MatDialogRef<any> {
    return this.dialog.open<T, any, R>(modal);
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }

}
