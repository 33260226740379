import {
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { IMenu } from '../../../interfaces/menu';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuStoreService } from '../../../services/menu/menu-store.service';
import { isFormChanged } from '../../../shared/function';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @ViewChild('inputFile') inputMenu!: ElementRef<HTMLInputElement>;
  private edit = false;
  public menuData!: IMenu;

  @Input() set menu(menu: IMenu) {
    this.menuData = menu;
    this.form.patchValue({
      name: menu.name,
      happyHours: menu.happyHours,
      hidden: menu.hidden,
    });
    this.form.setValidators(
      isFormChanged({
        name: menu.name,
        happyHours: menu.happyHours,
        hidden: menu.hidden
      })
    );
  }

  @Input() set isEdit(value: boolean) {
    this.edit = value && this.menuData.ownerType === 'RESTAURANT';
    this.resetInputFile();
  }
  @Input() restaurantId!: number;
  @Input() isMainMenu = true;
  fileMenu: File | null = null;
  fileIcon: File | null = null;
  isUpdating = false;

  // tslint:disable-next-line:adjacent-overload-signatures
  get menu(): IMenu {
    return this.menuData;
  }

  get menuImage(): string {
    if (this.menu.pages && this.menu.pages?.length > 0) {
      return this.menu.pages[0];
    }
    else {
      return '';
    }
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get isEdit(): boolean {
    return this.edit;
  }

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    menuFile: new FormControl(null),
    iconFile: new FormControl(null),
    happyHours: new FormControl(false),
    hidden: new FormControl(false),
  });

  constructor(
    private menuState: MenuStoreService
  ) {}

  update(data: {
    id: number;
    values: {
      name: string;
      happyHours: boolean;
      hidden: boolean;
      menuFile: File | null;
      iconFile: File | null
    };
  }): void {
    this.isUpdating = true;
    const menuType = this.isMainMenu ? 'mainMenu' : 'happyMenu';
    this.menuState
      .updateMenu(data.id, menuType, this.restaurantId, { ...data.values })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.isUpdating = false;
        },
        () => (this.isUpdating = false)
      );
  }

  delete(restaurantId: number, menuId: number): void {
    this.isUpdating = true;
    this.menuState
      .removeRestaurantsMenu(restaurantId, menuId)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => (this.isUpdating = false),
        () => (this.isUpdating = false)
      );
  }

  onMenuFileChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files;
    if (file) {
      this.fileMenu = file[0];
      this.form.patchValue({ menuFile: file[0] });
    }
  }

  onIconFileChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files;
    if (file) {
      this.fileIcon = file[0];
      this.form.patchValue({ iconFile: file[0] });
    }
  }

  resetInputFile(): void {
    setTimeout(() => {
      this.inputMenu.nativeElement.value = '';
    }, 0);
    this.fileMenu = null;
    this.fileIcon = null;
    this.form.patchValue({ menuFile: null });
    this.form.patchValue({ iconFile: null });
  }
}
