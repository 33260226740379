<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.widget.title' | translate }}</h1>

    <div class="actions">
      <button class="btn btn__primary" *ngIf="isEdited" [disabled]="!form.dirty || !form.valid" (click)="onSubmit()">
        {{ 'pages.widget.actions.save' | translate }}
      </button>
      <button class="btn btn__secondary" *ngIf="isEdited" (click)="onCancel()">
        {{ 'pages.widget.actions.cancel' | translate }}
      </button>
      <button class="btn btn__secondary" *ngIf="!isEdited" (click)="isEdited = true">
        {{ 'pages.widget.actions.manage' | translate }}
      </button>
    </div>
  </div>
  <div class="content">
    <div class="col">
      <app-device [url]="this.widgetUrl" [previewConfig]="previewConfig"></app-device>
    </div>
    <div class="col" *ngIf="isEdited">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <fieldset>
          <legend>{{ 'pages.widget.form.main' | translate }}</legend>
          <mat-form-field class="field">
            <mat-label>{{ 'pages.widget.form.idleDelay' | translate }}</mat-label>
            <mat-select formControlName="idleDelay" >
              <mat-option [value]=0>{{ 'pages.widget.form.delay.disabled' | translate }}</mat-option>
              <mat-option [value]=10>10{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=15>15{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=20>20{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=25>25{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=30>30{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=35>35{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=40>40{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=45>45{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=50>50{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=55>55{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=60>60{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=70>70{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=80>80{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
              <mat-option [value]=90>90{{ 'pages.widget.form.delay.seconds' | translate }}</mat-option>
            </mat-select>
            <mat-hint>{{ 'pages.widget.form.delay.hint' | translate }}</mat-hint>
          </mat-form-field>
          <div class="field" *ngIf="this.form.get('idleDelay')?.value !== 0">
            <mat-checkbox formControlName="tabBarAutoHide" color="primary">{{ 'pages.widget.form.tabBarAutoHide' | translate }}</mat-checkbox>
          </div>
          <div><mat-label>{{ 'pages.widget.form.logo.title' | translate }}</mat-label>
            <app-form-file-upload
              appDragAndDropFiles
              formControlName="logo"
              accept="image/*"
            >{{ 'formFilUpload.dragAndDrop' | translate }}</app-form-file-upload>
          </div>
        </fieldset>

        <fieldset formGroupName="colors">
          <legend>{{ 'pages.widget.form.colors.title' | translate }}</legend>

          <div class="palette">
            <button type="button" *ngFor="let palette of palettes" [class.active]="isCurrentPalette(palette)" (click)="applyPalette(palette)">
              <div class="name">{{ palette.name }}</div>
              <div class="colors">
                <div class="color" [style.background-color]="palette.colors.primary"></div>
                <div class="color" [style.background-color]="palette.colors.secondary"></div>
                <div class="color" [style.background-color]="palette.colors.tertiary"></div>
              </div>
            </button>
          </div>
          <div class="fields">
            <mat-form-field appearance="standard">
              <mat-label>{{ 'pages.widget.form.colors.primary.label' | translate }}</mat-label>
              <input matInput formControlName="primary" type="color">
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'pages.widget.form.colors.secondary.label' | translate }}</mat-label>
              <input matInput formControlName="secondary" type="color">
            </mat-form-field>
            <mat-form-field appearance="standard">
              <mat-label>{{ 'pages.widget.form.colors.tertiary.label' | translate }}</mat-label>
              <input matInput formControlName="tertiary" type="color">
            </mat-form-field>
          </div>
        </fieldset>

        <fieldset formGroupName="pages">
          <legend>{{ 'pages.widget.form.pages.legend' | translate }}</legend>
          <mat-tab-group>
            <mat-tab label="{{ 'pages.widget.form.pages.menu.label' | translate }}" formGroupName="menu">
              <div class="field">
                <mat-checkbox formControlName="enabled" color="primary">{{ 'pages.widget.form.pages.menu.enable' | translate }}</mat-checkbox>
              </div>
              <div class="field" [class.blur]="!this.form.get('pages.menu.enabled')?.value">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'pages.widget.form.pages.menu.name' | translate }}</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="form.get('pages.menu.name')?.errors?.required">
                    {{ 'pages.widget.form.errors.required' | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('pages.menu.name')?.errors?.maxlength">
                    {{ 'pages.widget.form.errors.maxlength' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field" [class.blur]="!this.form.get('pages.menu.enabled')?.value">
                <label>{{ 'pages.widget.form.pages.menu.icon' | translate }}</label>
                <app-form-file-upload
                  appDragAndDropFiles
                  formControlName="icon"
                  accept="image/*"
                >{{ 'formFilUpload.dragAndDrop' | translate }}</app-form-file-upload>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'pages.widget.form.pages.serviceCenter.label' | translate }}" formGroupName="serviceCenter">
              <div class="field">
                <mat-checkbox formControlName="enabled" color="primary">{{ 'pages.widget.form.pages.serviceCenter.enable' | translate }}</mat-checkbox>
              </div>
              <div class="field" [class.blur]="!this.form.get('pages.serviceCenter.enabled')?.value">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'pages.widget.form.pages.serviceCenter.name' | translate }}</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="form.get('pages.serviceCenter.name')?.errors?.required">
                    {{ 'pages.widget.form.errors.required' | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('pages.serviceCenter.name')?.errors?.maxlength">
                    {{ 'pages.widget.form.errors.maxlength' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field" [class.blur]="!this.form.get('pages.serviceCenter.enabled')?.value">
                <app-form-file-upload
                  appDragAndDropFiles
                  formControlName="icon"
                  accept="image/*"
                >{{ 'formFilUpload.dragAndDrop' | translate }}</app-form-file-upload>
              </div>
            </mat-tab>
            <mat-tab label="{{ 'pages.widget.form.pages.invoice.label' | translate }}" formGroupName="invoice">
              <div class="field">
                <mat-checkbox formControlName="enabled" color="primary">{{ 'pages.widget.form.pages.invoice.enable' | translate }}</mat-checkbox>
              </div>
              <div class="field" [class.blur]="!this.form.get('pages.invoice.enabled')?.value">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'pages.widget.form.pages.invoice.name' | translate }}</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="form.get('pages.invoice.name')?.errors?.required">
                    {{ 'pages.widget.form.errors.required' | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('pages.invoice.name')?.errors?.maxlength">
                    {{ 'pages.widget.form.errors.maxlength' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field" [class.blur]="!this.form.get('pages.invoice.enabled')?.value">
                <label>{{ 'pages.widget.form.pages.invoice.icon' | translate }}</label>
                <app-form-file-upload
                  appDragAndDropFiles
                  formControlName="icon"
                  accept="image/*"
                >{{ 'formFilUpload.dragAndDrop' | translate }}</app-form-file-upload>
              </div>
            </mat-tab>
          </mat-tab-group>
        </fieldset>

        <fieldset formGroupName="callWaiterButtons">
          <legend>{{ 'pages.widget.form.callWaiterButtons.legend' | translate }}</legend>
          <mat-tab-group>
            <mat-tab label="{{ 'pages.widget.form.callWaiterButtons.callWaiter.legend' | translate }}" formGroupName="callWaiter">
              <div class="field">
                <mat-checkbox formControlName="enabled" color="primary">
                  {{ 'pages.widget.form.callWaiterButtons.callWaiter.enable' | translate }}
                </mat-checkbox>
              </div>
              <div class="field" [class.blur]="!this.form.get('callWaiterButtons.callWaiter.enabled')?.value">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'pages.widget.form.callWaiterButtons.callWaiter.name' | translate }}</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="form.get('callWaiterButtons.callWaiter.name')?.errors?.required">
                    {{ 'pages.widget.form.errors.required' | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('callWaiterButtons.callWaiter.name')?.errors?.maxlength">
                    {{ 'pages.widget.form.errors.maxlength' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field" [class.blur]="!this.form.get('callWaiterButtons.callWaiter.enabled')?.value">
                <mat-form-field>
                  <mat-label>{{ 'pages.widget.form.callWaiterButtons.colorOfLights.label' | translate }}</mat-label>
                  <mat-select formControlName="lightsColor">
                    <mat-option [value]="null">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.noLights' | translate }}</mat-option>
                    <mat-option value="#ff0000">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.red' | translate }}</mat-option>
                    <mat-option value="#00ff00">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.green' | translate }}</mat-option>
                    <mat-option value="#ffff00">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.yellow' | translate }}</mat-option>
                    <mat-option value="#0000ff">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.blue' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab
              label="{{ 'pages.widget.form.callWaiterButtons.anotherRound.legend' | translate }}"
              formGroupName="anotherRound"
              *ngIf="!!this.form.get('pages.serviceCenter.enabled')?.value"
            >
              <div class="field">
                <mat-checkbox formControlName="enabled" color="primary">
                  {{ 'pages.widget.form.callWaiterButtons.anotherRound.enable' | translate }}
                </mat-checkbox>
              </div>
              <div class="field" [class.blur]="!this.form.get('callWaiterButtons.anotherRound.enabled')?.value">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'pages.widget.form.callWaiterButtons.anotherRound.name' | translate }}</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="form.get('callWaiterButtons.anotherRound.name')?.errors?.required">
                    {{ 'pages.widget.form.errors.required' | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('callWaiterButtons.anotherRound.name')?.errors?.maxlength">
                    {{ 'pages.widget.form.errors.maxlength' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field" [class.blur]="!this.form.get('callWaiterButtons.anotherRound.enabled')?.value">
                <mat-form-field>
                  <mat-label>{{ 'pages.widget.form.callWaiterButtons.colorOfLights.label' | translate }}</mat-label>
                  <mat-select formControlName="lightsColor">
                    <mat-option [value]="null">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.noLights' | translate }}</mat-option>
                    <mat-option value="#ff0000">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.red' | translate }}</mat-option>
                    <mat-option value="#00ff00">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.green' | translate }}</mat-option>
                    <mat-option value="#ffff00">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.yellow' | translate }}</mat-option>
                    <mat-option value="#0000ff">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.blue' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
            <mat-tab
              label="{{ 'pages.widget.form.callWaiterButtons.requestBill.legend' | translate }}"
              formGroupName="requestBill"
              *ngIf="!!this.form.get('pages.invoice.enabled')?.value
            ">
              <div class="field">
                <mat-checkbox formControlName="enabled" color="primary">
                  {{ 'pages.widget.form.callWaiterButtons.requestBill.enable' | translate }}
                </mat-checkbox>
              </div>
              <div class="field" [class.blur]="!this.form.get('callWaiterButtons.requestBill.enabled')?.value">
                <mat-form-field appearance="standard">
                  <mat-label>{{ 'pages.widget.form.callWaiterButtons.requestBill.name' | translate }}</mat-label>
                  <input matInput formControlName="name">
                  <mat-error *ngIf="form.get('callWaiterButtons.requestBill.name')?.errors?.required">
                    {{ 'pages.widget.form.errors.required' | translate }}
                  </mat-error>
                  <mat-error *ngIf="form.get('callWaiterButtons.requestBill.name')?.errors?.maxlength">
                    {{ 'pages.widget.form.errors.maxlength' | translate }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="field" [class.blur]="!this.form.get('callWaiterButtons.requestBill.enabled')?.value">
                <mat-form-field>
                  <mat-label>{{ 'pages.widget.form.callWaiterButtons.colorOfLights.label' | translate }}</mat-label>
                  <mat-select formControlName="lightsColor">
                    <mat-option [value]="null">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.noLights' | translate }}</mat-option>
                    <mat-option value="#ff0000">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.red' | translate }}</mat-option>
                    <mat-option value="#00ff00">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.green' | translate }}</mat-option>
                    <mat-option value="#ffff00">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.yellow' | translate }}</mat-option>
                    <mat-option value="#0000ff">{{ 'pages.widget.form.callWaiterButtons.colorOfLights.blue' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-tab>
          </mat-tab-group>
        </fieldset>
      </form>

      <div class="reset-wrapper">
        <button (click)="onResetToFactory()">
          Reset to default configuration
        </button>
      </div>
    </div>
  </div>
</div>
