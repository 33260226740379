import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Chain } from '../interfaces/chain';
import { AdminService } from '../services/admin/admin.service';

@Injectable({
  providedIn: 'root'
})
export class ChainResolver implements Resolve<Chain> {
  constructor(private adminService: AdminService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Chain> {
    return this.adminService.getChainById((route.paramMap.get('id') ?? 0) as number);
  }
}
