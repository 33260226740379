import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpecialsService } from './specials.service';
import { ISpecial, ISpecialState } from '../../interfaces/special';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, share, switchMap} from 'rxjs/operators';
import {FileUploadService} from '../file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class SpecialsStoreService {

  constructor(
    private specialsService: SpecialsService,
    private fileUpload: FileUploadService,
    private dialog: MatDialog
  ) {}

  private state$ = new BehaviorSubject<ISpecialState>({
    specials: [],
    specialsSequence: [],
    loader: false,
  });

  get getSpecials$(): Observable<ISpecial[]> {
    return this.state$.asObservable().pipe(map((state) => state.specials));
  }

  get isLoading$(): Observable<boolean> {
    return this.state$.asObservable().pipe(map((state) => state.loader));
  }

  get sequence$(): Observable<number[]>{
    return this.state$.asObservable().pipe(map((state) => state.specialsSequence));
}
  public fetchSpecials(
    chainId: number,
    filters?: { scheduleStartFrom?: string; scheduleEndTo?: string; status?: string; restaurantId?: number[] }
  ): void {
    this.toggleLoader(true);
    this.specialsService.getChainSpecials(chainId, filters)
      .subscribe((result) => {
        this.toggleLoader(false);
        this.state$.next({
         ...this.state$.getValue(),
          specialsSequence: result.map(special => special.id),
          specials: result,
        });
      }, () => {
        this.toggleLoader(false);
      });
  }

  public fetchSpecialById(chainId: number, specialId: number): void {
    this.toggleLoader(true);
    this.specialsService.getChainSpecialById(chainId, specialId)
     .subscribe((result) => {
        this.toggleLoader(false);
        this.state$.next({
         ...this.state$.getValue(),
          specials: [result],
        });
      }, () => {
        this.toggleLoader(false);
      });
  }

  public addSpecial(
    chainId: number,
    name: string,
    scheduleStart: string,
    scheduleEnd: string,
    contentFile: File,
    restaurantIdsToAttach: number[] | null
  ): Observable<ISpecial> {
    const contentFileUpload$ = this.fileUpload.upload(contentFile).pipe(
      map(event => event.intent.fileId.toString())
    );

    const subscription$ = contentFileUpload$.pipe(
      switchMap(contentFileId => {
        return this.specialsService.addSpecial(
          chainId,
          name,
          scheduleStart,
          scheduleEnd,
          contentFileId,
          restaurantIdsToAttach
        );
      }),
      share()
    );

    subscription$.subscribe((result) => {
      const state = this.state$.getValue();
      const specials = [result, ...state.specials];
      const sequence = [result.id, ...state.specialsSequence];

      this.state$.next({
        ...state,
        specialsSequence: sequence,
        specials,
      });
      this.dialog.closeAll();
    });

    return subscription$;
  }

  public updateSpecial(
    specialId: number,
    chainId: number,
    name: string,
    scheduleStart: string,
    scheduleEnd: string,
    contentFile: File,
    restaurantIdsToAttach: number[] | null
  ): Observable<ISpecial> {
    let contentFileUpload$: Observable<string | null> = of(null);

    if (contentFile) {
      contentFileUpload$ = this.fileUpload.upload(contentFile).pipe(
        map(event => event.intent.fileId.toString())
      );
    }

    const subscription$ = contentFileUpload$.pipe(
      switchMap(contentFileId => {
        return this.specialsService.updateSpecial(
          specialId,
          chainId,
          name,
          scheduleStart,
          scheduleEnd,
          contentFileId,
          restaurantIdsToAttach
        );
      }),
      share()
    );

    subscription$.subscribe((result) => {
      const state = this.state$.getValue();
      const specials = state.specials.map((special) => {
        if (special.id === result.id) {
          return result;
        }
        return special;
      });

      this.state$.next({
        ...state,
        specials,
      });
      this.dialog.closeAll();
    });

    return subscription$;
  }

  public deleteSpecial(
    chainId: number,
    specialId: number
  ): Observable<{ message: string }> {
    const subscription$ = this.specialsService.deleteSpecial(
      chainId,
      specialId
    );

    subscription$.subscribe((result) => {
      const state = this.state$.getValue();
      const specials = state.specials.filter((special) => special.id !== specialId);
      const sequence = state.specialsSequence.filter((id) => id !== specialId);

      this.state$.next({
       ...state,
        specialsSequence: sequence,
        specials,
      });
    });
    return subscription$;
  }

  public updateSpecialSequence(
    chainId: number,
    sequence: number[]
  ): Observable<ISpecial[]> | void {
    if (this.isSequenceChanged(sequence, this.state$.getValue().specialsSequence)) {
      const subscription$ = this.specialsService.updateSpecialsSequence(
        chainId,
        sequence
      );

      subscription$.subscribe((result) => {
        this.state$.next({
          ...this.state$.getValue(),
          specials: result,
          specialsSequence: result.map(special => special.id),
        });
        this.dialog.closeAll();
      });
      return subscription$;
    }
  }

  public toggleLoader(value: boolean): void {
    const state = this.state$.getValue();
    this.state$.next({
      ...state,
      loader: value,
    });
  }

  private isSequenceChanged(currentSequence: number[], initialSequence: number[]): boolean {

    if (initialSequence.length !== currentSequence.length) {
      return true;
    }

    for (let i = 0; i < initialSequence.length; i++) {
      if (initialSequence[i] !== currentSequence[i]) {
        return true;
      }
    }
    return false;
  }

  public adjustEndDate(endDate: Date | string): string {

    if (typeof endDate === 'string') {
       const date = new Date(endDate);
       date.setHours(23);
       date.setMinutes(59);
       date.setSeconds(59);

       return date.toISOString();
    }

    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    return endDate.toISOString();
  }

}
