import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SpecialsStoreService } from '../../../services/specials/specials-store.service';


@UntilDestroy()
@Component({
  selector: 'app-add-special',
  templateUrl: './add-special.component.html',
  styleUrls: ['./add-special.component.scss']
})
export class AddSpecialComponent {

  isLoading = false;
  dateNaw = new Date();

  form = new FormGroup({
    range: this.formBuilder.group({
      start: new FormControl(null, Validators.required),
      end: new FormControl(null, Validators.required),
    }),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    specialFile: new FormControl(null, [Validators.required]),
    restaurants: new FormControl({ value: null, disabled: true }),
    toAll: new FormControl(true),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chainId: number,
      allRestaurants: IRestaurantInfo[]
    },
    private specialsStore: SpecialsStoreService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.form.get('toAll')?.valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((value: boolean) => {
      if (value) {
        this.form.get('restaurants')?.reset({
          value: [],
          disabled: true,
        });
      } else {
        this.form.get('restaurants')?.reset({
          value: null,
          disabled: false,
        });
      }
    });
  }

  public onSubmit(): void {
    this.isLoading = true;

    this.specialsStore.addSpecial(
      this.data.chainId,
      this.form.value.name,
      this.form.value.range.start.toISOString(),
      this.specialsStore.adjustEndDate(this.form.value.range.end),
      this.form.value.specialFile,
      this.form.value.toAll ? [] : this.form.value.restaurants
    ).pipe(
      untilDestroyed(this)
    ).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }
}
