import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ITable } from '../../interfaces/table';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, share } from 'rxjs/operators';
import { WebsocketService } from '../websocket/websocket.service';
import { NotifierService } from 'angular-notifier';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  public loading = true;
  public items: ITable[] = [];

  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    public auth: AuthService,
    private http: HttpClient,
    private translate: TranslateService,
    private websocket: WebsocketService,
    private notifier: NotifierService,
  ) {}

  public fetchAll(id: number): Observable<any> {
    const request = this.http.get(`/restaurants/${id}/tables`).pipe(share());

    request.subscribe((response: any) => {
      this.loading = false;
      this.items = response;
      this.sort();
    }, (err) => {
      console.log(err);
    });

    return request;
  }

  websocketInit(): void {
    this.websocket.subscribe((message) => {
      switch (message.type) {
        case 'echo':
          this.websocketOnEcho(message.data);
          break;
        case 'loginSuccessful':
          this.websocketOnLoginSuccessful();
          break;
        case 'deleteTable':
        case 'addTable':
        case 'updateTable':
          this.fetchAll(message.data.restaurantId);
          break;
        case 'updateTable.field':
          const tableId = message.data.tableId;
          const field = message.data.field;
          const value = message.data.value;

          switch (field) {
            case 'batteryLevel':
              this.websocketOnBatteryLevel(
                +tableId,
                value.level,
                value.charging
              );
              break;
            case 'online':
              this.websocketOnConnectionStatus(
                +tableId,
                value
              );
              break;
            case 'deviceUuid':
              this.websocketOnDeviceUuid(
                +tableId,
                value
              );
              break;
            default:
              break;
          }
          break;
        case 'attachTablesToWaiter':
          this.fetchAll(message.data.attachedTables[0].restaurantId);
          break;
        case 'detachTablesFromWaiter':
          this.fetchAll(message.data.detachedTables[0].restaurantId);
          break;
        case 'tablesList':
          this.loading = true;
          this.items = message.data;
          this.loading = false;

          this.sort();
          break;
        default:
          break;
      }
    });

    this.websocket.connected$.pipe(
      distinctUntilChanged(),
      filter((status) => !status)
    ).subscribe(() => {
      this.items.map((table) => {
        table.online = undefined;
        table.batteryLevel.level = null;
        table.batteryLevel.charging = false;

        this.setTableStatus({
          tableId: table.id,
          status: false,
          pay: false,
          repeat: false,
        });
      });

      this.sort();
    });
  }

  websocketOnEcho(data: any): void {
    if ((data.tableId && typeof data.status !== undefined) || (data.tableId && typeof data.pay !== undefined)) {
      this.setTableStatus(data);
    }

    if (data.tableId && typeof data.playlistItems !== undefined) {
      const table = this.findById(data.tableId);

      if (table) {
        table.playlistItems = data.playlistItems;
      }
    }
  }

  websocketOnConnectionStatus(id: number, status = true): void {
    const table = this.findById(id);

    if (table) {
      table.online = status;

      if (!status) {
        table.batteryLevel = { level: null, charging: false };
      }

      this.sort();
    }
  }

  public websocketOnDeviceUuid(id: number, deviceUuid: string | null): void {
    const table = this.findById(id);

    if (table) {
      table.deviceUuid = deviceUuid;

      if (table.deviceUuid === null) {
        table.online = false;
        table.status = false;
        table.statusPay = false;
        table.statusRepeat = false;

        if (table.statusTimer) {
          clearTimeout(table.statusTimer);
        }
      }

      this.sort();
    }
  }

  websocketOnBatteryLevel(id: number, level: number | null, charging = false): void {
    const table = this.findById(id);

    if (table) {
      table.batteryLevel = { level, charging };
    }
  }

  private setTableStatus(data: any): void {
    this.items.forEach((table: any) => {
      if (table.id === data.tableId) {
        table.status = data.status ?? false;
        table.statusPay = data.pay ?? false;
        table.statusRepeat = data.repeat ?? false;

        if (table.statusTimer) {
          clearTimeout(table.statusTimer);
        }

        if (table.status || table.statusPay || table.statusRepeat) {
          table.statusTimer = setTimeout(() => {
            this.setTableStatus({
              tableId: table.id,
              status: false,
              pay: false,
              repeat: false,
            });
          }, 10000);

          this.sort();
        }
      }
    });
  }

  websocketOnLoginSuccessful(): void {
    console.log('WS: account successful');
  }

  public sendMessage(table: ITable, data: object): void {
    this.websocket.sendMessage({
      type: 'echoToWidget',
      tableId: table.id,
      data
    });
  }

  public cancelCalled(table: ITable): void {
    return this.sendMessage(table, { status: false });
  }

  public cancelCalledPay(table: ITable): void {
    return this.sendMessage(table, { pay: false });
  }

  public cancelCalledRepeat(table: ITable): void {
    return this.sendMessage(table, { repeat: false });
  }

  public requestPlaylistItems(table: ITable): void {
    this.sendMessage(table, {
      getPlaylistItems: true,
    });
  }

  public requestDeviceTurnOff(table: ITable): void {
    this.sendMessage(table, {
      deviceTurnOff: true,
    });
  }

  public sort(): void {
    this.items.sort((a, b) => {
      return (
        Number(!!b.deviceUuid) - Number(!!a.deviceUuid) ||
        Number(b.status ?? false) - Number(a.status ?? false) ||
        Number(b.statusPay ?? false) - Number(a.statusPay ?? false) ||
        Number(b.statusRepeat ?? false) - Number(a.statusRepeat ?? false) ||
        Number(b.online ?? false) - Number(a.online ?? false) ||
        a.id - b.id
      );
    });
  }

  public findById(id: number): ITable | undefined {
    return this.items.find((item) => item.id === id);
  }

  public remove(restaurantId: number, tableId: number): Observable<void> {
    const request = this.http.delete<void>(`/restaurants/${restaurantId}/tables/${tableId}`).pipe(
      share()
    );

    request.subscribe((response) => {
      this.notifier.notify('success', this.translate.instant('pages.tables.messages.deletedTable'));
    });

    return request;
  }
}
