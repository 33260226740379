<input [id]="inputId" type="file" [accept]="accept" />

<div *ngIf="previewUrl || file" class="file-preview">
  <img *ngIf="previewUrl" [src]="previewUrl" alt="Preview" />
  <div *ngIf="!previewUrl && file" class="file-name">{{ file.name }}</div>
</div>
<div class="file-select">
  <label [for]="inputId">{{ 'formFilUpload.browseForFile' | translate }}</label>
</div>

<ng-content></ng-content>
