<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.waiters.modal.title' | translate }}</h1>

  <form class="modal__form" [formGroup]="editWaiter" (submit)="editNewWaiter(editWaiter)" #formDir="ngForm">
    <div class="modal__subtitle">
      <h2 class="hr">{{ 'pages.waiters.modal.info' | translate }}</h2>
    </div>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name" required>
      <mat-error *ngIf="editWaiter.controls.name.touched">
        <span *ngIf="editWaiter.controls.name.errors?.required">
          {{ 'pages.waiters.modal.required' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.email' | translate }}</mat-label>
      <input [readonly]="true" matInput formControlName="email" required>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.newPassword' | translate }}</mat-label>
      <input type="password" matInput formControlName="password" >
      <mat-error *ngIf="editWaiter.controls.password.touched">
        <span *ngIf="editWaiter.controls.password.errors?.maxlength">
          {{ 'pages.waiters.modal.maxlength' | translate }}
        </span>
        <span *ngIf="editWaiter.controls.password.errors?.minlength">
          {{ 'pages.waiters.modal.minlength' | translate }}
        </span>
      </mat-error>
    </mat-form-field>

    <div class="modal__subtitle">
      <h2 class="hr">{{ 'pages.waiters.modal.tables' | translate }}</h2>
    </div>

    <mat-form-field floatLabel="always" class="login-wrap__text">
      <mat-label>{{ 'pages.waiters.modal.tables' | translate }}</mat-label>
      <mat-select multiple placeholder="{{ 'pages.waiters.modal.selectTables' | translate }}"  (ngModelChange)="checked($event)"  formControlName="tableIds">
        <mat-option  *ngFor="let table of tables; let i =index"  [value]="table.id">
          {{table.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" [disabled]="loading || editWaiter.invalid" type="submit">
        {{ 'pages.waiters.modal.editWaiter' | translate }}
      </button>
    </div>
  </form>
</div>
