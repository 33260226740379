<ng-container *ngIf="tables && tables.length > 0; else tablesEmpty">
<div class="waiters__item">
  <ol class="waiters__item__tables">
    <li *ngFor="let table of tables.slice(0, 5);">
      {{ table.name }}
    </li>
  </ol>
  <ng-container *ngIf="tables.length > 5">
    <span class="more" (click)="showMore()">{{ 'pages.waiters.table.more' | translate }}</span>
  </ng-container>
</div>
</ng-container>

<ng-template #tablesEmpty>
  <span class="waiters__item empty">{{ 'pages.waiters.table.empty' | translate }}</span>
</ng-template>

