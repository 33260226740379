<div class="wrapper">
  <div class="login-wrap">

    <h1 class="login-wrap__title">{{ 'login.title' | translate }}</h1>
    <form
      class="login-wrap__form"
      novalidate
      [formGroup]="loginForm"
      (submit)="login(loginForm)"
    >
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'login.email.label' | translate }}</mat-label>
        <input matInput formControlName="email">
        <mat-error *ngIf="loginForm.controls.email.touched && loginForm.controls.email.invalid">
          <span *ngIf="loginForm.controls.email.errors?.required">{{ 'login.email.required' | translate }}</span>
          <span *ngIf="loginForm.controls.email.errors?.email">{{ 'login.email.notValid' | translate }}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'login.password.label' | translate }}</mat-label>
        <input type="password" matInput formControlName="password">
        <mat-error *ngIf="loginForm.controls.password.touched && loginForm.controls.password.invalid">
          <span *ngIf="loginForm.controls.password.errors?.required">{{ 'login.password.required' | translate }}</span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>Language</mat-label>
        <select matNativeControl (change)="handlerChangeLanguage($event)" formControlName="language">
          <option *ngFor="let lang of availableLanguages" [value]="lang">
            {{ 'languages.' + lang + '.label' | translate }}
          </option>
        </select>
      </mat-form-field>

      <div class="login-wrap__submit-btn">
        <button class="btn btn__primary btn__block" type="submit" [disabled]="loginForm.invalid">
          {{ 'login.submit' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
