import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NotifierService} from 'angular-notifier';
import {WebsocketService} from '../../../services/websocket/websocket.service';
import {ConnectionStatusService} from '../../../services/connection-status.service';
import {filter, pluck} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute} from '@angular/router';
import {IRestaurantInfo} from '../../../interfaces/restaurant';
import {HeaderMenuItem} from '../../../interfaces/header-links';
import {TableService} from '../../../services/table/table.service';
import {AuthService} from '../../../services/auth/auth.service';
import {UserRoles} from '../../../shared/enums/userRoles';


@UntilDestroy()
@Component({
  selector: 'app-restaurant-layout',
  templateUrl: './restaurant-layout.component.html',
  styleUrls: ['./restaurant-layout.component.scss']
})
export class RestaurantLayoutComponent implements OnInit, OnDestroy {

  public menuLinks: HeaderMenuItem[] = [
    {
      route: 'tables',
      title: 'navigation.tables',
      access: true
    },
    {
      route: 'menu',
      title: 'navigation.menu',
      access: true
    },
    {
      route: 'restaurant-account',
      title: 'navigation.restaurantAccount',
      access: true
    },
    {
      route: 'waiters',
      title: 'navigation.waiters',
      access: true
    },
    {
      route: 'happy-hours',
      title: 'navigation.happyHours',
      access: true
    },
  ];

  public backLink: any[] | null = null;

  public restaurant!: IRestaurantInfo;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private notifier: NotifierService,
    private websocketService: WebsocketService,
    private connectionStatus: ConnectionStatusService,
    private tableService: TableService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      pluck('restaurant'),
      untilDestroyed(this),
    ).subscribe((restaurant: IRestaurantInfo) => {
      this.restaurant = restaurant;

      if (['chains', 'chain'].includes(this.authService.area())) {
        this.backLink = ['/chains', restaurant.chainId.toString()];
      }

      if (this.authService.area() === 'restaurant' && restaurant.meta.userRole === UserRoles.waiter) {
        this.menuLinks = [];
      }
    });

    this.websocketService.authorized$.pipe(
      filter((status) => status),
      untilDestroyed(this)
    ).subscribe(() => {
      this.websocketService.subscribeToRestaurant(this.restaurant.id);
    });
  }

  ngOnDestroy(): void {
    this.websocketService.unsubscribeToRestaurant(this.restaurant.id);
    this.tableService.items = [];
  }


  get isOnline(): boolean {
    return this.connectionStatus.online$.getValue();
  }

  get isWSConnected(): boolean {
    return this.websocketService.connected$.getValue();
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public handlerChangeLanguage(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      this.translate.use(event.target.value);
      localStorage.setItem('lang', event.target.value);
    }
  }

}
