<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.menu.specialOffer.title' | translate }}</h1>
    <div class="actions">
      <button type="button" class="btn btn__secondary" *ngIf="isEdit" [disabled]="isLoading" (click)="requestPlaylistItem()">
        {{ 'pages.menu.specialOffer.request' | translate }}
      </button>
      <button type="button" *ngIf="!isEdit; else toggleEdit" class="btn btn__icon btn__secondary" (click)="isEdit = true">
        <span>{{ 'pages.menu.specialOffer.request' | translate }}</span>
      </button>
      <ng-template #toggleEdit>
        <button type="button" class="btn btn__icon btn__active" (click)="isEdit = false; save();">
          <span>{{ 'pages.menu.specialOffer.save' | translate }}</span>
        </button>
      </ng-template>
    </div>
  </div>

  <div class="empty" *ngIf="!isLoading && items.length === 0; else itemsAvailable">
    <div class="message">{{ 'pages.menu.specialOffer.noPlaylist' | translate }}</div>
    <button type="button" class="btn btn__primary" [disabled]="isLoading" (click)="isEdit = true; requestPlaylistItem();">
      {{ 'pages.menu.specialOffer.request' | translate }}
    </button>
  </div>

  <ng-template #itemsAvailable>
    <div class="items">
      <div class="header">
        <div class="header_col">{{ 'pages.menu.specialOffer.media' | translate }}</div>
        <div class="header_col">{{ 'pages.menu.specialOffer.menu' | translate }}</div>
      </div>

      <ng-container *ngIf="!isLoading; else loading">
        <div class="item" *ngFor="let item of items, let i = index">
          <div class="item_col">
            <div class="field-name">{{ item.name }}</div>
            <div class="field-type">{{ item.mediaType }}</div>
          </div>
          <div class="item_col">
            <mat-form-field style="display: block;" floatLabel="always">
              <mat-select [disabled]="!isEdit" [(ngModel)]="item.menuId">
                <mat-option> - {{ 'pages.menu.specialOffer.empty' | translate }} - </mat-option>
                <mat-option *ngFor="let menu of menus; let i = index" [value]="menu.id">
                  {{ menu.name }}
                  <span *ngIf="menu.happyHours"> - {{ 'pages.menu.specialOffer.happyHours' | translate }}</span>
                  <span *ngIf="menu.hidden"> ({{ 'pages.menu.specialOffer.hidden' | translate }})</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-template #loading>
        <div class="item" *ngFor="let i of [].constructor(5)">
          <div class="item_col">
            <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
          </div>
          <div class="item_col">
            <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
