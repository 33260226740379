import {Component, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { WebsocketService } from '../../../services/websocket/websocket.service';
import { ConnectionStatusService } from '../../../services/connection-status.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeaderMenuItem } from '../../../interfaces/header-links';
import {filter, pluck} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Chain } from '../../../interfaces/chain';
import { AuthService } from '../../../services/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'app-chain-layout',
  templateUrl: './chain-layout.component.html',
  styleUrls: ['./chain-layout.component.scss']
})
export class ChainLayoutComponent implements OnInit, OnDestroy {

  public menuLinks: HeaderMenuItem[] = [
    {
      route: 'restaurants',
      title: 'navigation.restaurants',
      access: true
    },
    {
      route: 'menu',
      title: 'navigation.menu',
      access: true
    },
    {
      route: 'specials',
      title: 'navigation.specials',
      access: true
    },
    {
      route: 'happy-hours',
      title: 'navigation.happyHours',
      access: true
    },
    {
      route: 'widget',
      title: 'navigation.widget',
      access: true
    },
    {
      route: 'settings',
      title: 'navigation.settings',
      access: true
    },
  ];

  public backLink: any[] | null = null;

  public chain!: Chain;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private notifier: NotifierService,
    private websocket: WebsocketService,
    private connectionStatus: ConnectionStatusService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      pluck('chain'),
      untilDestroyed(this),
    ).subscribe((chain: Chain) => {
      this.chain = chain;

      if (['chains'].includes(this.authService.area())) {
        this.backLink = ['/chains'];
      }

      this.websocket.authorized$.pipe(
        filter((status) => status),
        untilDestroyed(this)
      ).subscribe(() => {
        this.websocket.subscribeToChain(this.chain.id);
      });
    });
  }

  get isOnline(): boolean {
    return this.connectionStatus.online$.getValue();
  }

  get isWSConnected(): boolean {
    return this.websocket.connected$.getValue();
  }

  get currentLanguage(): string {
    return this.translate.currentLang || this.translate.defaultLang;
  }

  get availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  public handlerChangeLanguage(event: Event): void {
    if (event.target instanceof HTMLSelectElement) {
      this.translate.use(event.target.value);
      localStorage.setItem('lang', event.target.value);
    }
  }

  ngOnDestroy(): void {
    this.websocket.unsubscribeToRestaurant(this.chain.id);
  }

}
