<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.chains.settings.chainTitle' | translate }}</h1>
  </div>

  <form class="modal__form info"
        [formGroup]="chainInfo"
        (submit)="updateChainInfo(chainInfo)"
  >
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.chains.settings.name' | translate }}</mat-label>
      <input matInput appFormInputTrim formControlName="name">
      <mat-error *ngIf="chainInfo.controls.name.touched">
          <span *ngIf="chainInfo.controls.name.errors?.required">
            {{ 'pages.chains.modal.required' | translate }}
          </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.chains.settings.email' | translate }}</mat-label>
      <input matInput appFormInputTrim readonly formControlName="email">
    </mat-form-field>

    <div class="password-enable-wrapper">
      <mat-checkbox
        color="primary"
        [checked]="isChangePasswordEnabled"
        (change)="enableChangingPasswordToggle()">{{ 'pages.chains.settings.changePassword' | translate }}</mat-checkbox>
    </div>

    <ng-container *ngIf="isChangePasswordEnabled">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.chains.settings.oldPassword' | translate }}</mat-label>
        <input type="text" matInput formControlName="oldPassword">
        <mat-error *ngIf="chainInfo.controls.oldPassword.invalid">
          <span *ngIf="chainInfo.controls.oldPassword.errors?.required">
            {{ 'pages.chains.settings.requiredPassFields' | translate }}
          </span>
          <span *ngIf="chainInfo.controls.oldPassword.errors?.minlength">
            {{ 'pages.chains.modal.passwordMinLength' | translate }}
          </span>
          <span *ngIf="chainInfo.controls.oldPassword.errors?.maxlength">
            {{ 'pages.chains.modal.passwordMaxLength' | translate }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.chains.settings.newPassword' | translate }}</mat-label>
        <input type="text" matInput formControlName="newPassword">

        <mat-error *ngIf="chainInfo.controls.newPassword.invalid">
          <span *ngIf="chainInfo.controls.newPassword.errors?.required">
            {{ 'pages.chains.settings.requiredPassFields' | translate }}
          </span>
          <span *ngIf="chainInfo.controls.newPassword.errors?.minlength">
            {{ 'pages.chains.modal.passwordMinLength' | translate }}
          </span>
          <span *ngIf="chainInfo.controls.newPassword.errors?.maxlength">
            {{ 'pages.chains.modal.passwordMaxLength' | translate }}
          </span>
        </mat-error>
      </mat-form-field>
    </ng-container>

    <div class="btn-wrap">
      <button class="btn btn__primary" type="submit" [disabled]="chainInfo.invalid || !chainInfo.dirty || !isPasswordChanged">
        <ng-container *ngIf="loading; else saveButton">
          <mat-spinner diameter="22" color="secondary"></mat-spinner>
        </ng-container>
        <ng-template #saveButton>{{ 'pages.chains.settings.save' | translate }}</ng-template>
      </button>
      <button *ngIf="currentChain?.meta?.userRole==='SUPER_ADMIN'"
              class="btn btn__warning"
              type="button"
              (click)="confirmToRemoveChainAdmin()"
      >{{ 'pages.chains.settings.remove' | translate }}</button>
    </div>
  </form>

</div>
