import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { NotifierService } from 'angular-notifier';
import { AnyIntegration } from '../../../interfaces/restaurant';
import { filter, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-edit-integration',
  templateUrl: './edit-integration.component.html',
  styleUrls: ['./edit-integration.component.scss']
})
export class EditIntegrationComponent implements OnInit {
  loading = false;
  public editIntegrationForm: FormGroup = this.generateForm();
  public restaurantId!: number;
  public integration!: AnyIntegration;
  private integrationId!: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private restaurantService: RestaurantService,
    public dialogRef: MatDialogRef<any>,
    public notifier: NotifierService
  ) {}

  ngOnInit(): void {
    this.restaurantId = this.data.restaurantId;
    this.integrationId = this.data.integrationId;
    this.editIntegrationForm.markAllAsTouched();
    this.getIntegration();
  }

  private generateForm(integration?: AnyIntegration): FormGroup {
    const urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~[\\]+=\\-/,]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );

    if (integration?.provider === 'VAST') {
      return this.formBuilder.group({
        vastTagUrl: [integration?.credentials.vastTagUrl || '', [Validators.pattern(urlRegex), Validators.required]]
      });
    } else if (integration?.provider === 'vistarMedia') {
      return this.formBuilder.group({
        apiKey: [integration?.credentials.apiKey || '', [Validators.required, Validators.maxLength(255)]],
        networkId: [integration?.credentials.networkId || '', [Validators.required, Validators.maxLength(255)]],
        venueId: [integration?.credentials.venueId || '', [Validators.required, Validators.maxLength(255)]]
      });
    } else if (integration?.provider === 'kelseysMenu') {
      return this.formBuilder.group({
        storeNumber: [
          integration?.credentials.storeNumber
          || '', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(255)]
        ]
      });
    } else {
      return this.formBuilder.group({});
    }
  }

  getIntegration(): void {
    this.restaurantService.getIntegrationById(this.restaurantId, this.integrationId)
      .pipe(
        take(1)
      )
      .subscribe((integration) => {
        this.integration = integration;
        this.editIntegrationForm = this.generateForm(integration);
        this.editIntegrationForm.patchValue(integration.credentials);
      });
  }

  public editIntegration(): void {
    this.loading = true;
    this.integration.credentials = this.editIntegrationForm.value;

    this.restaurantService.editIntegration(this.restaurantId, this.integration).pipe(
      filter(Boolean),
      untilDestroyed(this),
    ).subscribe((data) => {
      this.loading = false;
      this.dialogRef.close(data);
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.editedIntegration'));
    });
  }

  public removeIntegration(): void {
    this.loading = true;
    this.restaurantService.removeIntegration(this.restaurantId, this.integrationId).pipe(
      untilDestroyed(this),
    ).subscribe(() => {
      this.loading = false;
      this.dialogRef.close(this.integrationId.toString());
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.deletedIntegration'));
    });
  }
}
