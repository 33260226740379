import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { switchMap, take } from 'rxjs/operators';
import { IWaiter } from 'src/app/interfaces/waiter';
import { ITable } from 'src/app/interfaces/table';
import { TableService } from 'src/app/services/table/table.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-waiter',
  templateUrl: './edit-waiter.component.html',
  styleUrls: ['./edit-waiter.component.scss'],
})
export class EditWaiterComponent implements OnInit {
  loading = false;
  private tableIdsString = 'tableIds';
  public editWaiter: FormGroup = this.generateForm();
  public restaurantId!: number;
  public tablesId: number[] = [];
  public tables!: ITable[];
  public waiter!: IWaiter;
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private restaurantService: RestaurantService,
    public dialogRef: MatDialogRef<any>,
    public notifier: NotifierService,
    public table: TableService
  ) {}

  ngOnInit(): void {
    this.restaurantId = this.data.restaurantId.toString();
    this.editWaiter.markAllAsTouched();
    this.populateData();
  }

  editNewWaiter(form: FormGroup): void {
    this.loading = true;
    const tableIdsToAttach: number[] = form.value.tableIds
      .filter((val: number) => !this.waiter.tableIds.includes(val))
      .slice();
    const tableIdsToDetach: number[] = this.waiter.tableIds
      .filter((val: number) => !form.value.tableIds.includes(val))
      .slice();
    const password = form.value.password;
    let payload = {
      idStaff: this.waiter.id,
      id: this.restaurantId,
      name: form.value.name,
      email: form.value.email,
    };
    if (tableIdsToAttach.length > 0) {
      payload = Object.assign({ tableIdsToAttach }, payload);
    }
    if (tableIdsToDetach.length > 0) {
      payload = Object.assign({ tableIdsToDetach }, payload);
    }
    if (password.length > 0) {
      payload = Object.assign({ password }, payload);
    }

    this.restaurantService.editWaiter(payload).subscribe((data) => {
      this.dialogRef.close(data);
      this.notifier.notify('success', this.translate.instant('pages.waiters.messages.editedWaiter'));
    });
  }
  private generateForm(waiter?: IWaiter): FormGroup {
    return this.formBuilder.group({
      name: [waiter?.name || '', Validators.required],
      email: [waiter?.email || '', [Validators.required, Validators.email]],
      tableIds: [waiter?.tableIds],
      password: ['', [Validators.minLength(4), Validators.maxLength(30)]],
    });
  }

  public checked(checkedTables: string[]): void {
    // working with choosen tables
  }

  private setControls(waiter: IWaiter): void {
    this.editWaiter.patchValue(waiter);
    this.editWaiter.controls[this.tableIdsString].setValue(waiter.tableIds);
  }

  populateData(): void {
    this.restaurantService
      .getWaiterById(this.restaurantId, this.data.waiterId)
      .pipe(
        switchMap((waiter) => {
          this.waiter = waiter;
          this.waiter.tableIds = waiter.meta.tables.map((table: { id: number; }) => table.id);
          this.generateForm(waiter);
          this.setControls(waiter);
          return this.restaurantService.getAllTables(this.data.restaurantId);
        }),
        take(1)
      )
      .subscribe((tables) => {
        this.tables = tables;
        for (const table of tables) {
          this.tablesId.push(parseInt(table.id, 10));
        }
      });
  }
}
