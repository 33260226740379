import { Component, Inject } from '@angular/core';
import { ISpecial } from '../../../interfaces/special';
import { IRestaurantInfo} from '../../../interfaces/restaurant';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpecialsStoreService } from '../../../services/specials/specials-store.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-edit-special',
  templateUrl: './edit-special.component.html',
  styleUrls: ['./edit-special.component.scss']
})
export class EditSpecialComponent {

  public isLoading = false;
  public isChang = false;

  form = new FormGroup({
    range: this.formBuilder.group({
      start: new FormControl(this.data.special.scheduleStart, Validators.required),
      end: new FormControl(this.data.special.scheduleEnd, Validators.required),
    }),
    name: new FormControl(this.data.special.name, [Validators.required, Validators.minLength(3)]),
    specialFile: new FormControl(null),
    restaurants: new FormControl(this.data.special.attachedToAll
      ? {value: null, disabled: true}
      : this.data.special.attachedRestaurantIds
    ),
    toAll: new FormControl(this.data.special.attachedToAll),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chainId: number,
      allRestaurants: IRestaurantInfo[],
      special: ISpecial
    },
    private specialsStore: SpecialsStoreService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.form.get('toAll')?.valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((value: boolean) => {
      if (value) {
        this.form.get('restaurants')?.reset({
          value: [],
          disabled: true,
        });
      } else {
        this.form.get('restaurants')?.reset({
          value: null,
          disabled: false,
        });
      }
    });
    this.form.get('range.start')?.valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(_ => {
      this.isChang = true;
    });
  }

  onSubmit(): void {
    this.isLoading = true;

    const startDateTime = new Date(this.form.value.range.start).toISOString();
    const endDateTime = this.specialsStore.adjustEndDate(this.form.value.range.end);

    this.specialsStore.updateSpecial(
      this.data.special.id,
      this.data.chainId,
      this.form.value.name,
      startDateTime,
      endDateTime,
      this.form.value.specialFile,
      this.form.value.toAll ? [] : this.form.value.restaurants
    ).pipe(
      untilDestroyed(this)
    ).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }

  public myFilterStart(inD: Date | null): boolean {
    const startDateTime = new Date(new Date().setHours(0, 0, 0, 0));

    if (!inD) {
      return true;
    }
    return inD >= startDateTime;
  }

  public myFilterEmpty(): boolean {
    return true;
  }
}
