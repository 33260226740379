<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.menu.modal.title' | translate }}</h1>
  <form class="modal__form" novalidated [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.menu.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim type="text" formControlName="name" />
      <mat-error *ngIf="form.controls.name.touched">
        <span *ngIf="form.controls.name.errors?.required">{{ 'pages.menu.modal.required' | translate }}</span>
        <span *ngIf="form.controls.name.errors?.minlength">{{ 'pages.menu.modal.min' | translate }}</span>
      </mat-error>
    </mat-form-field>

    <div class="files">
      <div class="files__item">
        <mat-label class="label">{{ 'pages.menu.modal.upload' | translate }}</mat-label>
        <app-form-file-upload
          appDragAndDropFiles
          formControlName="menuFile"
          accept="application/pdf"
        >
          {{ 'formFilUpload.dragAndDrop' | translate }}
        </app-form-file-upload>
      </div>
      <div class="files__item">
        <mat-label class="label">{{ 'pages.menu.modal.icon' | translate }}</mat-label>
        <app-form-file-upload
          appDragAndDropFiles
          formControlName="iconFile"
          accept="image/svg+xml"
        >
          {{ 'formFilUpload.dragAndDrop' | translate }}
        </app-form-file-upload>
      </div>
    </div>

    <mat-form-field class="login-wrap__text">
      <mat-label>Restautants</mat-label>
      <mat-select formControlName="restaurants" multiple>
        <mat-option *ngFor="let restaurant of data.allRestaurants" [value]="restaurant.id">
          {{ restaurant.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      class="example-margin"
      color="primary"
      formControlName="toAll"
    >
      {{ 'pages.chains.modal.addToAllRest' | translate }}
    </mat-checkbox>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="form.invalid || isLoading">
        <ng-container *ngIf="isLoading; else createMenu">
          <mat-spinner diameter="22" color="secondary"></mat-spinner>
        </ng-container>
        <ng-template #createMenu>{{ 'pages.menu.modal.createMenu' | translate }}</ng-template>
      </button>
    </div>
  </form>
</div>
