import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RestaurantService } from '../../services/restaurant/restaurant.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {filter, pluck, share, tap} from 'rxjs/operators';
import { AdminService } from '../../services/admin/admin.service';
import { NotifierService } from 'angular-notifier';
import { IRestaurantInfo } from '../../interfaces/restaurant';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { IMenu } from '../../interfaces/menu';
import { MenuStoreService } from '../../services/menu/menu-store.service';
import { AddMenuComponent } from '../../components/modals/add-menu/add-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {WebsocketService} from '../../services/websocket/websocket.service';

@UntilDestroy()
@Component({
  selector: 'app-happy-hours-page',
  templateUrl: './happy-hours-page.component.html',
  styleUrls: ['./happy-hours-page.component.scss']
})
export class HappyHoursPageComponent implements OnInit {

  public env = environment;

  public timeFrom: any;
  public timeTo: any;
  public menuUrl: string | undefined;
  public data: any;
  public file: any;
  public setHappyHoursTime: object[] | null = null;
  public isLoading = false;
  public happyHours: {day: number, name: string, status: boolean, timeFrom: string | null, timeTo: string | null}[] = [];
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public isEdit = false;
  public restaurantInfo!: IRestaurantInfo;
  public menus: IMenu[] = [];
  public isMenuLoading = false;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private notify: NotifierService,
    private translate: TranslateService,
    private restaurant: RestaurantService,
    private admin: AdminService,
    private menuStore: MenuStoreService,
    private websocket: WebsocketService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.parent?.data.pipe(
      pluck('restaurant'),
      tap(restaurant => {
        this.restaurantInfo = restaurant;
        this.getRestaurantInfo(restaurant.id);
        this.initMenu();
      }),
      untilDestroyed(this),
    ).subscribe();

    this.happyHours = moment.weekdays().map((day: string, index: number) => {
      return {
        day: index,
        name: day,
        status: false,
        timeFrom: null,
        timeTo: null
      };
    });

    this.initWebsocket();
  }

  initMenu(): void {
    this.menuStore.fetchMenus({restaurantId: this.restaurantInfo.id, menuType: 'happyMenu'});

    this.menuStore.getMenus$.
    pipe(untilDestroyed(this))
      .subscribe((menus) => {
        this.menus = menus;
      });

    this.menuStore.isLoading$.pipe(
      untilDestroyed(this)
    ).subscribe((load) => this.isMenuLoading = load);
  }

  private initWebsocket(): void {
    this.websocket.inboundMessage$.pipe(
      untilDestroyed(this)
    ).subscribe((message: any) => {
      if (message.type === 'updateMenu') {
        message.data.attachedRestaurantIds.map((id: number) => {
          if (id === this.restaurantInfo.id) {
            this.menuStore.fetchMenu(this.restaurantInfo.id, message.data.id);
          }
        });
      }
    });
  }

  createMenu(): void {
    this.dialog.open(AddMenuComponent, {
      data: {restaurantId: this.restaurantInfo.id, happyHours: true}
    });
  }

  changeListener($event: any): void {
    this.data = $event.target.files;
    this.postFile(this.data);
  }

  postFile(inputValue: any): void {
    this.isLoading = true;
    this.file = inputValue[0];

    this.restaurant.uploadHappyHoursPdf(this.file, this.restaurantInfo.id).subscribe((response) => {
      this.isLoading = false;

      if (response.happyMenuUrl) {
        this.http.get(this.env.apiUrl + response.happyMenuUrl).pipe(
          filter((data: any) => !!(data.pages) && data.pages.length > 0)
        ).subscribe((data: any) => {
          this.menuUrl = this.env.apiUrl + data.pages[0] + '?t=' + moment().unix();
        });
      }

      this.notify.notify('success', response.message);
    }, () => {
      this.isLoading = false;
    });
  }

  happyHoursSubmit(): void {
    const selectedDays = this.happyHours.filter((day: any) => day.status).map((day: any) => {
      const setSelectTimeFrom = moment(day.timeFrom, 'HH:mm');
      const setSelectTimeTo = moment(day.timeTo, 'HH:mm');
      return {day: day.day, timeFrom: setSelectTimeFrom.format('HH:mm'), timeTo: setSelectTimeTo.format('HH:mm')};
    });

    this.restaurant.uploadHappyHoursData({
      id: this.restaurantInfo.id,
      happyHours: selectedDays
    }).pipe(share()).subscribe((response) => {
      this.updateHappyHours(response.happyHours);
      this.notify.notify('success', this.translate.instant('pages.happyHours.setSuccess'));
    });
  }

  updateHappyHours(items: any): void {
    this.happyHours.map((day: any) => {
      day.timeTo = null;
      day.timeFrom = null;
      day.status = false;
      items.map((item: any) => {
        if (day.day === item.day) {
          day.timeTo = moment(item.timeTo, 'HH:mm').format('HH:mm');
          day.timeFrom = moment(item.timeFrom, 'HH:mm').format('HH:mm');
          day.status = true;
        }
      });
    });
  }

  getRestaurantInfo(id: number): void {
    this.admin.getRestaurantInfo(id).subscribe((response: IRestaurantInfo) => {

      if (response.happyMenuUrl) {
        this.http.get(this.env.apiUrl + response.happyMenuUrl).pipe(
          filter((data: any) => {
            return !!(data.pages) && data.pages.length > 0;
          })
        ).subscribe((data: any) => {
          this.menuUrl = this.env.apiUrl + data.pages[0] + '?t=' + moment().unix();
        });
      }

      this.setHappyHoursTime = response.happyHours;
      this.updateHappyHours(response.happyHours);
    });
  }
}
