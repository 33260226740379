<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.restaurantAccount.editIntegration' | translate }}</h1>

  <form class="modal__form" [formGroup]="editIntegrationForm">
    <div class="modal__subtitle">
      <h2 class="hr">
        {{ 'pages.restaurantAccount.provider' | translate }}:
        {{this.integration?.provider === "VAST"
        ? "VAST" : this.integration?.provider === "vistarMedia"
          ? "Vistar Media" : "Kelsey Menu"}}
      </h2>
    </div>

    <ng-container *ngIf="this.integration?.provider === 'VAST'">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVastTagUrl' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="vastTagUrl">
        <mat-error *ngIf="editIntegrationForm.get('vastTagUrl')?.errors?.pattern && editIntegrationForm.get('vastTagUrl')?.touched">
          {{ 'pages.restaurantAccount.urlValidation' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="this.integration?.provider === 'vistarMedia'">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVistarMediaApiKey' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="apiKey">
        <mat-error *ngIf="editIntegrationForm.get('apiKey')?.errors && editIntegrationForm.get('apiKey')?.touched">
          {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVistarMediaNetworkId' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="networkId">
        <mat-error *ngIf="editIntegrationForm.get('networkId')?.errors && editIntegrationForm.get('networkId')?.touched">
          {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.adVistarMediaVenueId' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="venueId">
        <mat-error *ngIf="editIntegrationForm.get('venueId')?.errors && editIntegrationForm.get('venueId')?.touched">
          {{ 'pages.restaurantAccount.maxLengthValidation' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="this.integration?.provider === 'kelseysMenu'">
      <mat-form-field class="login-wrap__text">
        <mat-label>{{ 'pages.restaurantAccount.storeNumber' | translate }}</mat-label>
        <input matInput appFormInputTrim formControlName="storeNumber">
        <mat-error *ngIf="editIntegrationForm.get('storeNumber')?.errors && editIntegrationForm.get('storeNumber')?.touched">
          {{ 'pages.restaurantAccount.maxLengthNumberValidation' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>


    <div class="modal__submit-btn">
      <button class="btn btn__primary" [disabled]="loading || editIntegrationForm.invalid || !editIntegrationForm.dirty" (click)="editIntegration()">
        {{ 'pages.restaurantAccount.save' | translate }}
      </button>
      <button class="btn btn__warning" (click)="removeIntegration()">
        {{ 'pages.restaurantAccount.delete' | translate }}
      </button>
    </div>

  </form>
</div>

