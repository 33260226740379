<div class="container">
  <div class="title-wrap">
    <h1 class="title">{{ 'pages.restaurants.title' | translate }}</h1>
    <div class="actions">
      <button class="btn btn__primary btn__icon" *ngIf="isEdited" (click)="openAddRestaurant()">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M0.999878 8H14.9999" stroke="currentColor" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
        </svg>
        <span class="add">{{ 'pages.restaurants.actions.add' | translate }}</span>
      </button>
      <button class="btn btn__icon" [ngClass]="{'btn__secondary': !isEdited, 'btn__active': isEdited}" (click)="isEdited = !isEdited">
        <span *ngIf="!isEdited">{{ 'pages.restaurants.actions.manage' | translate }}</span>
        <span *ngIf="isEdited">{{ 'pages.restaurants.actions.done' | translate }}</span>
      </button>
    </div>
  </div>
  <div class="empty" *ngIf="!isEmpty">
    <span>{{ 'pages.restaurants.empty' | translate }}</span>
  </div>
  <div class="restaurant-list">
    <div class="restaurant-list__item" *ngFor="let restaurant of allRestaurants" [routerLink]="['/restaurants', restaurant.id, 'tables']">
      <div class="item-column">{{restaurant.name}}</div>
      <div class="item-column">{{restaurant.email}}</div>
    </div>
  </div>
</div>
