<div class="modal">
  <div class="close-link">
    <span class="close" mat-dialog-close></span>
  </div>
  <h2 mat-dialog-title>{{ title }}</h2>

  <mat-dialog-content>
    <p>{{ message }}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button color="primary"
            mat-stroked-button
            [mat-dialog-close]="true">{{ 'pages.tables.modal.ok' | translate }}</button>

    <button mat-stroked-button mat-dialog-close>{{ 'pages.tables.modal.cancel' | translate }}</button>
  </mat-dialog-actions>

</div>
