<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h2 mat-dialog-title>{{ 'pages.tables.modal.widgetCode' | translate }}</h2>

  <form class="modal__form" novalidated [formGroup]="newWidgetCodeForm">
    <mat-form-field class="login-wrap__text">
      <input matInput appFormInputTrim formControlName="widgetCode">
      <mat-error *ngIf="newWidgetCodeForm.controls.widgetCode.touched && newWidgetCodeForm.controls.widgetCode.invalid">
        <span *ngIf="newWidgetCodeForm.controls.widgetCode.errors?.required">
          {{ 'pages.tables.modal.mandatoryField' | translate }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>

  <mat-dialog-actions align="center">
    <button
      color="primary"
      mat-stroked-button
      [disabled]="newWidgetCodeForm.invalid"
      (click)="linkNewDevice()"
      [mat-dialog-close]="true"
    >{{ 'pages.tables.modal.ok' | translate }}</button>
    <button mat-stroked-button mat-dialog-close>{{ 'pages.tables.modal.cancel' | translate }}</button>
  </mat-dialog-actions>
</div>
