<div class="modal">
  <div class="close-link">
    <span class="close" [mat-dialog-close]></span>
  </div>
  <h1 class="modal__title">{{ 'pages.specials.modal.title' | translate }}</h1>
  <form class="modal__form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.specials.modal.name' | translate }}</mat-label>
      <input matInput appFormInputTrim type="text" formControlName="name">
      <mat-error *ngIf="form.controls.name.touched">
        <span *ngIf="form.controls.name.errors?.required">{{ 'pages.specials.modal.required' | translate }}</span>
        <span *ngIf="form.controls.name.errors?.minlength">{{ 'pages.specials.modal.min' | translate }}</span>
      </mat-error>
    </mat-form-field>

    <div class="file">
      <mat-label class="label">{{ 'pages.specials.modal.upload' | translate }}</mat-label>
      <app-form-file-upload
        appDragAndDropFiles
        formControlName="specialFile"
        accept="image/*"
      >
        {{ 'formFilUpload.dragAndDrop' | translate }}
      </app-form-file-upload>
    </div>

    <mat-form-field class="login-wrap__text">
      <mat-label>{{ 'pages.restaurants.title' | translate }}</mat-label>
      <mat-select formControlName="restaurants" multiple>
        <mat-option *ngFor="let restaurant of data.allRestaurants" [value]="restaurant.id">
          {{ restaurant.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      class="example-margin"
      color="primary"
      formControlName="toAll"
    >
      {{ 'pages.chains.modal.addToAllRest' | translate }}
    </mat-checkbox>

    <mat-form-field class="data-range">
      <mat-label>{{ 'pages.specials.modal.range' | translate }}</mat-label>
      <mat-date-range-input [min]="dateNaw" formGroupName="range"  [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-hint>{{ 'pages.specials.modal.start' | translate }}: MM/DD/YYYY – {{ 'pages.specials.modal.end' | translate }}: MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker disabled="false" #picker></mat-date-range-picker>

      <mat-error *ngIf="form.controls.name.touched">
        <span *ngIf="form.get('range.start')?.errors">{{ 'pages.specials.modal.invalidStart' | translate }}</span>
        <span *ngIf="form.get('range.end')?.errors">{{ 'pages.specials.modal.invalidEnd' | translate }}</span>
      </mat-error>
    </mat-form-field>

    <div class="modal__submit-btn">
      <button class="btn btn__primary" type="submit" [disabled]="form.invalid || isLoading">
        <ng-container *ngIf="isLoading; else add">
          <mat-spinner diameter="22" color="secondary"></mat-spinner>
        </ng-container>
        <ng-template #add>{{ 'pages.specials.modal.createSpecials' | translate }}</ng-template>
      </button>
    </div>
  </form>
</div>
