import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { WidgetUIConfiguration } from '../interfaces/widget-ui-config';
import { ChainService } from '../services/chain.service';

@Injectable({
  providedIn: 'root'
})
export class ChainWidgetUiConfigResolver implements Resolve<WidgetUIConfiguration> {
  constructor(
    private readonly chainService: ChainService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WidgetUIConfiguration> {
    return this.chainService.getWidgetUiConfig((route.parent?.paramMap.get('id') ?? 0) as number);
  }
}
