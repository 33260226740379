import { Component, Input } from '@angular/core';
import { WaiterTable } from '../../../interfaces/table';
import { MatDialog } from '@angular/material/dialog';
import { WaiterTablesModalComponent } from '../../../components/modals/waiter-tables-list/waiter-tables-modal.component';

@Component({
  selector: 'app-waiter-tables',
  templateUrl: './waiter-tables.component.html',
  styleUrls: ['./waiter-tables.component.scss']
})
export class WaiterTablesComponent {

  @Input() tables: WaiterTable[] = [];
  @Input() waiterName = '';
  constructor(
    private dialog: MatDialog
  ) {}

  showMore(): void {
    this.dialog.open(WaiterTablesModalComponent, {data: {tables: this.tables, waiterName: this.waiterName}});
  }
}
