import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { MenuStoreService } from '../../../services/menu/menu-store.service';

@UntilDestroy()
@Component({
  selector: 'app-add-chain-menu',
  templateUrl: './add-chain-menu.component.html',
  styleUrls: ['./add-chain-menu.component.scss']
})
export class AddChainMenuComponent {

  isLoading = false;

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    menuFile: new FormControl(null, [Validators.required]),
    iconFile: new FormControl(null),
    restaurants: new FormControl({ value: null, disabled: true }),
    toAll: new FormControl(true),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chainId: number,
      happyHours: boolean,
      hidden: boolean,
      allRestaurants: IRestaurantInfo[]
    },
    private menuState: MenuStoreService,
  ) {
    this.form.get('toAll')?.valueChanges.pipe(
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((value: boolean) => {
      if (value) {
        this.form.get('restaurants')?.reset({
          value: [],
          disabled: true,
        });
      } else {
        this.form.get('restaurants')?.reset({
          value: null,
          disabled: false,
        });
      }
    });
  }

  public onSubmit(): void {
    this.isLoading = true;

    this.menuState.addChainMenu(
      this.data.chainId,
      this.form.value.name,
      this.data.happyHours,
      this.data.hidden,
      this.form.value.menuFile,
      this.form.value.toAll ? [] : this.form.value.restaurants,
      this.form.value.iconFile
    ).pipe(
      untilDestroyed(this)
    ).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }
}
