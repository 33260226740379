import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MenuStoreService } from '../../../services/menu/menu-store.service';

@UntilDestroy()
@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss'],
})
export class AddMenuComponent implements OnInit {
  file: File | null = null;
  isLoading = false;

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    menuFile: new FormControl(null, [Validators.required]),
    iconFile: new FormControl(null)
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { restaurantId: number; happyHours: boolean; hidden: boolean },
    private menuState: MenuStoreService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    this.isLoading = true;

    this.menuState.addMenu(
      this.data.restaurantId,
      this.form.value.name,
      this.data.happyHours,
      this.data.hidden,
      this.form.value.menuFile,
      this.form.value.iconFile
    ).pipe(
      untilDestroyed(this)
    ).subscribe(
      () => (this.isLoading = false),
      () => (this.isLoading = false)
    );
  }

  onMenuFileChange(event: Event): void {
    const file = (event.target as HTMLInputElement).files;
    if (file) {
      this.file = file[0];
      this.form.patchValue({ menuFile: file[0] });
    }
  }
}
