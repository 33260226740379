<div class="special">
  <div class="item-column image">
    <img [src]="specialData.content" alt="">
  </div>
  <div class="item-column description">
    <div class="name">
      <span class="caption">{{ 'pages.specials.name' | translate }}</span>
      <span>{{ specialData.name }}</span>
    </div>
    <div class="schedule">
      <span class="caption">{{ 'pages.specials.schedule' | translate }}</span>
      <span>
        {{ 'pages.specials.start' | translate }}{{ start }}
         -
        {{ 'pages.specials.end' | translate }}{{ end }}
      </span>
    </div>
  </div>
  <div class="item-column status">
    <span *ngIf="isActive" class="on">
      {{'pages.specials.active' | translate}}
    </span>
    <span *ngIf="!isActive" class="off">
      {{'pages.specials.inactive' | translate}}
    </span>
  </div>
  <div class="item-column controls">
    <button class="btn btn__icon edit-special" *ngIf="isEdited" (click)="openEditSpecial()">
      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" stroke="transparent" width="24"  height="24" viewBox="0 0 24 24" style=" fill:#000000;">
        <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z"></path>
      </svg>
    </button>

    <button class="btn btn__icon remove-special" *ngIf="isEdited" (click)="removeSpecial()">
      <svg width="24px" height="24px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336"/>
        <path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa"/>
      </svg>
    </button>
  </div>
</div>
