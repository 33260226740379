<div class="menu__skeleton-loader" *ngIf="isUpdating; else content">
  <div class="menu__skeleton-row img">
    <div class="menu__skeleton-field img">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="menu__skeleton-row info">
    <div class="menu__skeleton-field info">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="menu__skeleton-row info">
    <div class="menu__skeleton-field info">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
  </div>
</div>

<ng-template #content>
  <div class="menu__item" [ngClass]="{'shake': isEdit}" *ngIf="menu">
    <button class="btn delete" *ngIf="isEdit" (click)="chainMenuDelete(chainId, menuData.id)">
      <svg width="25px" height="25px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="#f44336"/>
        <path d="m350.273438 320.105469c8.339843 8.34375 8.339843 21.824219 0 30.167969-4.160157 4.160156-9.621094 6.25-15.085938 6.25-5.460938 0-10.921875-2.089844-15.082031-6.25l-64.105469-64.109376-64.105469 64.109376c-4.160156 4.160156-9.621093 6.25-15.082031 6.25-5.464844 0-10.925781-2.089844-15.085938-6.25-8.339843-8.34375-8.339843-21.824219 0-30.167969l64.109376-64.105469-64.109376-64.105469c-8.339843-8.34375-8.339843-21.824219 0-30.167969 8.34375-8.339843 21.824219-8.339843 30.167969 0l64.105469 64.109376 64.105469-64.109376c8.34375-8.339843 21.824219-8.339843 30.167969 0 8.339843 8.34375 8.339843 21.824219 0 30.167969l-64.109376 64.105469zm0 0" fill="#fafafa"/>
      </svg>
    </button>

    <form [formGroup]="form" (ngSubmit)="update({id: menu.id, values: form.value})">
      <div *ngIf="menu.file" class="menu__row menu__original_file">
        <img class="icon" src="assets/icons/pdf.svg" alt="" />
        <a class="name" [href]="menu.file.url" [matTooltip]="menu.file.name" target="_blank">{{ menu.file.name }}</a>
      </div>

      <div class="menu__image" [ngClass]="{overlay: isEdit && menu.processedAt}">
        <div class="menu__image-container" *ngIf="!menuFile">
          <img *ngIf="menu.pages && menu.pages.length > 0 else loader" src="{{menu.pages[0] ?? menu.pages[0]}}" alt="">
          <ng-template #loader>
            <mat-progress-spinner
              class="spinner"
              [color]="'primary'"
              [mode]="'indeterminate'"
              diameter="100"
            >
            </mat-progress-spinner>
          </ng-template>
        </div>
        <div class="menu-upload" [ngStyle]="{'display': isEdit  && menu.processedAt ? 'flex' : 'none'}">
          <label for="menuFile-{{menu.id}}" class="menu-upload__label">{{ 'pages.menu.menuContent.upload' | translate }}</label>
          <input #inputFile type="file" hidden id="menuFile-{{menu.id}}" accept="application/pdf" (change)="onMenuFileChange($event)">
          <div class="menu-upload__title" *ngIf="menuFile">
            <span class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.file' | translate }}: </span>
            <span class="menu__title">{{menuFile.name}}</span>
          </div>
        </div>
      </div>

      <div class="menu__row menu__row--vertical">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.icon' | translate }}: </div>
        <div class="menu__value menu__value--pattern" *ngIf="!isEdit">
          <img class="menu__value__img menu__value__img--large" *ngIf="!iconFile && menu.icon else noIcon" src="{{ menu.icon }}" alt="">
          <ng-template #noIcon>
            <img class="menu__value__img menu__value__img--large" src="assets/icons/noIcon.svg" alt="" />
            <span>{{ 'pages.menu.menuContent.noIcon' | translate }}</span>
          </ng-template>
        </div>
        <app-form-file-upload
          *ngIf="isEdit"
          appDragAndDropFiles
          formControlName="iconFile"
          accept="image/svg+xml"
          (change)="onIconFileChange($event)"
          class="menu__edit_icon"
          previewUrl="{{ menu.icon }}"
        >
          {{ 'formFilUpload.dragAndDrop' | translate }}
        </app-form-file-upload>
      </div>

      <div class="menu__row">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.menuName' | translate }}: </div>
        <div *ngIf="!isEdit" class="menu__value">{{ menu.name }}</div>
        <div *ngIf="isEdit" class="menu__edit_name">
          <input
            matInput
            appFormInputTrim
            type="text"
            formControlName="name"
          />
          <mat-error *ngIf="form.controls.name.touched">
            <span *ngIf="form.controls.name.errors?.required">{{ 'pages.menu.menuContent.required' | translate }}</span>
            <span *ngIf="form.controls.name.errors?.minlength">{{ 'pages.menu.menuContent.min' | translate }}</span>
          </mat-error>
        </div>
      </div>

      <div class="menu__row">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.happyHours' | translate }}: </div>
        <input *ngIf="isEdit" type="checkbox" formControlName="happyHours">
        <div *ngIf="!isEdit" class="menu__value">{{ (menu.happyHours ? 'common.yes' : 'common.no') | translate }}</div>
      </div>

      <div class="menu__row">
        <div class="menu__title menu__title--bold">{{ 'pages.menu.menuContent.hide' | translate }}: </div>
        <input *ngIf="isEdit" type="checkbox" formControlName="hidden">
        <div *ngIf="!isEdit" class="menu__value">{{ (menu.hidden ? 'common.yes' : 'common.no') | translate }}</div>
      </div>

      <div class="menu__row" [ngClass]="{'menu__row--vertical ': isEdit}">
        <div class="menu__title menu__title--bold">{{ 'pages.chains.menuContent.restaurants' | translate }}: </div>
        <div *ngIf="!isEdit" class="menu__value">
          <div *ngIf="attachedRestaurants">
            <span class="menu__restaurants-item" *ngFor="let restaurant of attachedRestaurants">{{ restaurant.name }}</span>
          </div>
          <div *ngIf="attachedRestaurants?.length === 0 && !menu.attachedToAll">
            <span class="menu__restaurants-item">{{ 'pages.chains.menuContent.notOne' | translate }}</span>
          </div>
          <div *ngIf="menu.attachedToAll">
            <span class="menu__restaurants-item">{{ 'pages.chains.modal.addToAllRest' | translate }}</span>
          </div>
        </div>
        <div *ngIf="isEdit" class="menu__edit_restaurants">
          <mat-checkbox formControlName="attachedToAll" color="primary">
            {{ 'pages.chains.modal.addToAllRest' | translate }}
          </mat-checkbox>

          <mat-form-field>
            <mat-select formControlName="restaurants" multiple>
              <mat-option
                *ngFor="let restaurant of restaurants"
                [value]="restaurant.id"
              >{{ restaurant.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isEdit" class="buttons__wrap">
        <button type="submit" class="btn btn__primary" [disabled]="form.invalid || !form.dirty">{{ 'pages.menu.menuContent.update' | translate }}</button>
      </div>
    </form>
  </div>
</ng-template>

