import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WaiterTable } from '../../../interfaces/table';

@Component({
  selector: 'app-waiter-tables-list',
  templateUrl: './waiter-tables-modal.component.html',
  styleUrls: ['./waiter-tables-modal.component.scss']
})
export class WaiterTablesModalComponent {

  public tables: WaiterTable[] = [];
  public waiterName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.tables = data.tables;
    this.waiterName = data.waiterName;
  }

}
