import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITable } from '../../../interfaces/table';

@Component({
  selector: 'app-add-new-widget-code',
  templateUrl: './add-new-widget-code.component.html',
  styleUrls: ['./add-new-widget-code.component.scss']
})
export class AddNewWidgetCodeComponent implements OnInit {
  public newWidgetCodeForm: FormGroup = this.generateForm();
  public table!: ITable;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { table: ITable },
    public dialogRef: MatDialogRef<any>,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.table = this.data.table;
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      widgetCode: ['', [Validators.required]]
    });
  }

  linkNewDevice(): void {
    const data = {
      tableId: this.table.id,
      restaurantId: this.table.restaurantId,
      widgetCode: this.newWidgetCodeForm.get('widgetCode')?.value
    };
    this.dialogRef.close(data);
  }
}
