import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnyIntegrationNew } from '../../../interfaces/restaurant';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-add-integration',
  templateUrl: './add-integration.component.html',
  styleUrls: ['./add-integration.scss']
})
export class AddIntegrationComponent {
  loading = false;
  public restaurantId: number;
  public addIntegration: FormGroup = this.generateForm();
  private integration!: AnyIntegrationNew;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    public notifier: NotifierService,
    private translate: TranslateService,
    private restaurantService: RestaurantService
  ) {
    this.restaurantId = this.data.restaurantId;
    this.addIntegration.get('provider')?.valueChanges
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(val => {
        this.addControl(val);
      });
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      provider: ['', [Validators.required]],
    });
  }

  public addControl(val: string): void {
    const urlRegex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~[\\]+=\\-/,]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
      'i'
    );

    if (val === 'VAST') {
      this.addIntegration.addControl(
        'vastTagUrl',
        new FormControl('', [Validators.required, Validators.pattern(urlRegex)]
        )
      );
      if (this.addIntegration.get('apiKey')) {
        this.addIntegration.removeControl('apiKey');
        this.addIntegration.removeControl('networkId');
        this.addIntegration.removeControl('venueId');
      } else if (this.addIntegration.get('storeNumber')) {
        this.addIntegration.removeControl('storeNumber');
      }
    } else if (val === 'vistarMedia') {
      this.addIntegration.addControl(
        'apiKey',
        new FormControl('', [Validators.required, Validators.maxLength(255)])
      );
      this.addIntegration.addControl(
        'networkId',
        new FormControl('', [Validators.required, Validators.maxLength(255)])
      );
      this.addIntegration.addControl(
        'venueId',
        new FormControl('', [Validators.required, Validators.maxLength(255)])
      );
      if (this.addIntegration.get('vastTagUrl')) {
        this.addIntegration.removeControl('vastTagUrl');
      } else if (this.addIntegration.get('storeNumber')) {
        this.addIntegration.removeControl('storeNumber');
      }
    } else if (val === 'kelseysMenu') {
      this.addIntegration.addControl(
        'storeNumber',
        new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(255)])
      );
      if (this.addIntegration.get('apiKey')) {
        this.addIntegration.removeControl('apiKey');
        this.addIntegration.removeControl('networkId');
        this.addIntegration.removeControl('venueId');
      } else if (this.addIntegration.get('vastTagUrl')) {
        this.addIntegration.removeControl('vastTagUrl');
      }
    }
  }

  public addNewIntegration(addIntegration: FormGroup): void {
    this.loading = true;

    if (addIntegration.value.provider === 'VAST'){
      this.integration = {
        provider: 'VAST',
        credentials: {
          vastTagUrl: addIntegration.value.vastTagUrl
        }
      };
    } else if (addIntegration.value.provider === 'vistarMedia'){
      this.integration = {
        provider: 'vistarMedia',
        credentials: {
          apiKey: addIntegration.value.apiKey,
          networkId: addIntegration.value.networkId,
          venueId: addIntegration.value.venueId
        }
      };
    } else if (addIntegration.value.provider === 'kelseysMenu'){
      this.integration = {
        provider: 'kelseysMenu',
        credentials: {
          storeNumber: addIntegration.value.storeNumber
        }
      };
    }

    this.restaurantService.addIntegration(this.restaurantId, this.integration).subscribe((data) => {
      this.loading = false;
      this.dialogRef.close(data);
      this.notifier.notify('success', this.translate.instant('pages.restaurantAccount.messages.addedIntegration'));
    }, () => {
      this.loading = false;
    });
  }
}
