import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService } from '../../../services/admin/admin.service';
import { NotifierService } from 'angular-notifier';
import { take } from 'rxjs/operators';
import { ITable } from 'src/app/interfaces/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-waiter',
  templateUrl: './add-waiter.component.html',
  styleUrls: ['./add-waiter.component.scss']
})
export class AddWaiterComponent implements OnInit {
  loading = false;
  public addWaiter: FormGroup = this.generateForm();
  public restaurantId: any;
  public tables?: ITable[];
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private restaurantService: RestaurantService,
    public dialogRef: MatDialogRef<any>,
    public adminService: AdminService,
    public notifier: NotifierService,
  ) {
  }

  ngOnInit(): void {
    this.restaurantId = this.data.restaurantId;
    this.restaurantService.getAllTables(this.data.restaurantId).pipe(take(1))
    .subscribe(data => this.tables = data);
  }

  addNewWaiter(form: FormGroup): void {
    this.loading = true;
    const tableIds = form.value.tables;
    let payload = {
      id: this.restaurantId,
      name: form.value.name,
      email: form.value.email,
      password: form.value.password,
    };
    if (tableIds.length > 0) {
      payload = Object.assign({ tableIds }, payload);
    }

    this.restaurantService.addWaiter(payload).subscribe((data) => {
      this.loading = false;
      this.dialogRef.close(data);
      this.notifier.notify('success', this.translate.instant('pages.waiters.messages.addedWaiter'));
    }, () => {
      this.loading = false;
    });
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]],
      tables: ['']
    });
  }

}
