import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { ISpecial } from '../../interfaces/special';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SpecialsService {
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private notifier: NotifierService
  ) {}

  public getChainSpecials(
    chainId: number,
    filters?: { scheduleStartFrom?: string; scheduleEndTo?: string; status?: string; restaurantId?: number[] }
  ): Observable<ISpecial[]> {
    let params: any = {};

    if (filters?.scheduleStartFrom) {
      params = { ...params, scheduleStartFrom: filters.scheduleStartFrom };
    }
    if (filters?.scheduleEndTo) {
      params = { ...params, scheduleEndTo: filters.scheduleEndTo };
    }
    if (filters?.status) {
      params = { ...params, status: filters.status };
    }
    if (filters?.restaurantId) {
      params = { ...params, restaurantId: filters?.restaurantId };
    }

    const subject$ = this.http.get<ISpecial[]>(`/chains/${chainId}/specials`, { params }).pipe(
      share()
    );

    subject$.subscribe();

    return subject$;
  }

  public getChainSpecialById(chainId: number, specialId: number): Observable<ISpecial> {
    return this.http.get<ISpecial>(`/chains/${chainId}/specials/${specialId}`);
  }

  public addSpecial(
    chainId: number,
    name: string,
    scheduleStart: string,
    scheduleEnd: string,
    contentFileId: string,
    restaurantIdsToAttach: number[] | null
  ): Observable<ISpecial> {
    const formData = new FormData();
    formData.append('chainId', chainId?.toString());
    formData.append('name', name);
    formData.append('scheduleStart', scheduleStart);
    formData.append('scheduleEnd', scheduleEnd);
    formData.append('fileId', contentFileId);
    formData.append('restaurantIdsToAttach', JSON.stringify(restaurantIdsToAttach));

    const subject$ = this.http.post<ISpecial>(`/chains/${chainId}/specials`, formData).pipe(
      share()
    );

    subject$.subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.specials.messages.addedSpecialSuccessfully'));
    });

    return subject$;
  }

  public updateSpecial(
    specialId: number,
    chainId: number,
    name: string,
    scheduleStart: string,
    scheduleEnd: string,
    contentFileId: string | null,
    restaurantIdsToAttach: number[] | null
  ): Observable<ISpecial> {
    const formData = new FormData();
    formData.append('chainId', chainId?.toString());
    formData.append('name', name);
    formData.append('scheduleStart', scheduleStart);
    formData.append('scheduleEnd', scheduleEnd);
    formData.append('restaurantIdsToAttach', JSON.stringify(restaurantIdsToAttach));

    if (contentFileId) {
      formData.append('fileId', contentFileId);
    }

    const subject$ = this.http.patch<ISpecial>(`/chains/${chainId}/specials/${specialId}`, formData).pipe(
      share()
    );

    subject$.subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.specials.messages.updateSpecials'));
    });

    return subject$;
  }

  public updateSpecialsSequence(chainId: number, idsSequence: number[]): Observable<ISpecial[]> {

    const subject$ = this.http.post<ISpecial[]>(`/chains/${chainId}/specials/sequence`, {idsSequence}).pipe(
      share()
    );

    subject$.subscribe(() => {
      this.notifier.notify('success', this.translate.instant('pages.specials.messages.uploadSpecialsSequence'));
    });

    return subject$;
  }

  public deleteSpecial(chainId: number, specialId: number): Observable<{ message: string }> {
    const subject$ = this.http.delete<{ message: string }>(`/chains/${chainId}/specials/${specialId}`).pipe(
      share()
    );

    subject$.subscribe((response) => {
      this.notifier.notify('success', this.translate.instant('pages.specials.messages.deletedSpecials'));
    });

    return subject$;
  }
}


