import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/assets/locale/')) {
      return next.handle(request.clone({
        headers: new HttpHeaders({
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache'
        })
      }));
    }

    return next.handle(request);
  }

}
