import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { TablesPageComponent } from '../../pages/tables-page/tables-page.component';
import { MenuPageComponent } from '../../pages/menu-page/menu-page.component';
import { SpecialOfferPageComponent } from '../../pages/special-offer-page/special-offer-page.component';
import { WaitersPageComponent } from '../../pages/waiters-page/waiters-page.component';
import { HappyHoursPageComponent } from '../../pages/happy-hours-page/happy-hours-page.component';
import { RestaurantAccountPageComponent } from '../../pages/restaurant-account-page/restaurant-account-page.component';
import { RestaurantsPageComponent } from '../../pages/restaurants-page/restaurants-page.component';
import { AuthGuard } from '../../guards/auth.guard';
import { AccountLayoutComponent } from '../../components/layouts/account-layout/account-layout.component';
import { HeaderComponent } from '../../components/common/header/header.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TableService } from 'src/app/services/table/table.service';
import { ChainsPageComponent } from '../../pages/chains-page/chains-page.component';
import { ChainMenuPageComponent } from '../../pages/chains-page/chain-menu-page/chain-menu-page.component';
import {
  ChainHappyHoursPageComponent
} from '../../pages/chains-page/chain-happy-hours-page/chain-happy-hours-page.component';
import { ChainSettingsPageComponent } from '../../pages/chains-page/chain-settings-page/chain-settings-page.component';
import { ChainResolver } from '../../resolvers/chain.resolver';
import { RestaurantResolver } from '../../resolvers/restaurant.resolver';
import {ChainLayoutComponent} from '../../components/layouts/chain-layout/chain-layout.component';
import {RestaurantLayoutComponent} from '../../components/layouts/restaurant-layout/restaurant-layout.component';
import {AuthService} from '../../services/auth/auth.service';
import {AdminService} from '../../services/admin/admin.service';
import {ChainsResolver} from '../../resolvers/chains.resolver';
import {WidgetPageComponent} from '../../pages/widget-page/widget-page.component';
import {ChainWidgetUiConfigResolver} from '../../resolvers/chain-widget-ui-config.resolver';
import {SpecialsPageComponent} from '../../pages/specials-page/specials-page.component';

const routes: Routes = [
  {
    path: '',
    component: AccountLayoutComponent,
    children: [
      {
        path: 'chains',
        component: ChainsPageComponent,
        resolve: { chains: ChainsResolver },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'chains/:id',
    component: ChainLayoutComponent,
    resolve: { chain: ChainResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'restaurants',
        pathMatch: 'full'
      },
      {
        path: 'restaurants',
        component: RestaurantsPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'menu',
        component: ChainMenuPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'specials',
        component: SpecialsPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'widget',
        component: WidgetPageComponent,
        resolve: {
          config: ChainWidgetUiConfigResolver
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'happy-hours',
        component: ChainHappyHoursPageComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        component: ChainSettingsPageComponent,
        canActivate: [AuthGuard],
      },
    ]
  },
  {
    path: 'restaurants/:id',
    component: RestaurantLayoutComponent,
    resolve: { restaurant: RestaurantResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'tables',
        pathMatch: 'full'
      },
      {
        path: 'tables',
        component: TablesPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'menu',
        component: MenuPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'special-offer',
        component: SpecialOfferPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'restaurant-account',
        component: RestaurantAccountPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'waiters',
        component: WaitersPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'happy-hours',
        component: HappyHoursPageComponent,
        canActivate: [AuthGuard]
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild((routes)),
    MatDialogModule,
    HttpClientModule,
    FontAwesomeModule,
    TranslateModule,
  ],
  exports: [RouterModule],
  providers: [],
  declarations: [
    AccountLayoutComponent,
    ChainLayoutComponent,
    RestaurantLayoutComponent,
    HeaderComponent,
  ],
})

export class AccountModule {

  constructor(
    private tableService: TableService,
    private authService: AuthService,
    private adminService: AdminService,
    ) {
    this.tableService.websocketInit();
    if (this.authService.hasToken()) {
      this.authService.fetchCurrentUser().subscribe((user) => {
        this.authService.user.next(user);
      });

      this.adminService.getAllRestaurants().subscribe((restaurants) => {
        this.authService.restaurants.next(restaurants);
      });

      this.adminService.getAllChains().subscribe((chains) => {
        this.authService.chains.next(chains);
      });
    }
  }

}
