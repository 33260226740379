import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '../../../services/admin/admin.service';
import { ChainPost } from '../../../interfaces/chain';

@Component({
  selector: 'app-add-chain',
  templateUrl: './add-chain.component.html',
  styleUrls: ['./add-chain.component.scss']
})
export class AddChainComponent  {

  public loading = false;
  public addChainForm: FormGroup = this.generateForm();

  constructor(
    public dialogRef: MatDialogRef<ChainPost>,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private admin: AdminService,
  ) { }


  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]]
    });
  }

  addChain(form: FormGroup): void {
    this.loading = true;

    const newChain = {
      name: form.value.name,
      email: form.value.email,
      password: form.value.password
    };

    this.admin.addNewChain(newChain).subscribe(
      (data: ChainPost) => {
        this.dialogRef.close(data);
        this.loading = false;
      },
      () => this.loading = false
    );
  }

}
