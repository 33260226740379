import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {FileUploadService} from '../../../services/file-upload.service';
import {switchMap} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-add-table',
  templateUrl: './add-table.component.html',
  styleUrls: ['./add-table.component.scss']
})
export class AddTableComponent implements OnInit {

  public addTable: FormGroup = this.generateForm();
  public qrFile: File | null = null;
  public restaurantId: any;
  public language = localStorage.getItem('lang') ?? this.translate.currentLang;
  public isUploading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private restaurantService: RestaurantService,
    public dialogRef: MatDialogRef<any>,
    private fileUpload: FileUploadService
  ) {
  }

  ngOnInit(): void {
    this.restaurantId = this.data?.restaurantId;
  }

  addNewTable(form: FormGroup): void {
    this.dialogRef.disableClose = true;
    this.isUploading = true;

    if (this.qrFile) {
      this.fileUpload.upload(this.qrFile).pipe(
        switchMap(event => {
          const qrCodeFileId = event.intent.fileId.toString();
          return this.restaurantService.addTable(
            this.restaurantId,
            form.value.name,
            form.value.widgetCode,
            form.value.description,
            qrCodeFileId
          );
        })
      ).subscribe((data) => {
        this.isUploading = false;
        this.dialogRef.close(data);
      });
    } else {
      this.restaurantService.addTable(
        this.restaurantId,
        form.value.name,
        form.value.widgetCode,
        form.value.description
      ).subscribe((data) => {
        this.isUploading = false;
        this.dialogRef.close(data);
      });
    }
    this.isUploading = false;
  }

  private generateForm(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      widgetCode: ['', [Validators.required]],
      description: [''],
      QRCodeFile: [null],
    });
  }

  onQrFileChange($event: Event): void {
    const file = ($event.target as HTMLInputElement).files;
    if (file) {
      this.qrFile = file[0];
      this.addTable.patchValue({ QRCodeFile: file[0] });
    }
  }
}
