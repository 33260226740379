import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { RestaurantService } from '../../../services/restaurant/restaurant.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRestaurantInfo } from '../../../interfaces/restaurant';
import { AdminService } from '../../../services/admin/admin.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-call-waite-config',
  templateUrl: './call-waite-config.component.html',
  styleUrls: ['./call-waite-config.component.scss']
})
export class CallWaiteConfigComponent implements OnInit {

  public restaurantId!: number;
  public callWaiteConfig!: {
    withCallWaiter: boolean,
    withCallWaiterToPay: boolean,
    withCallWaiterToRepeat: boolean
  };
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly formBuilder: FormBuilder,
    private restaurantService: RestaurantService,
    private adminService: AdminService,
    public dialogRef: MatDialogRef<any>
  ) {
    this.form = this.formBuilder.group({
      allOn: new FormControl(false),
      withCallWaiter: new FormControl(false),
      withCallWaiterToPay: new FormControl(false),
      withCallWaiterToRepeat: new FormControl(false),
    });
  }

  ngOnInit(): void {
    this.restaurantId = this.data.restaurantId;
    this.adminService.getRestaurantInfo(this.restaurantId).pipe(
      map(restaurant => restaurant.features),
      untilDestroyed(this)
    ).subscribe(features => {
      if (typeof features !== 'undefined') {
        this.callWaiteConfig = features;
        this.form.patchValue({
          withCallWaiter: this.callWaiteConfig.withCallWaiter,
          withCallWaiterToPay: this.callWaiteConfig.withCallWaiterToPay,
          withCallWaiterToRepeat: this.callWaiteConfig.withCallWaiterToRepeat,
        });
      }
    });

    this.updateAllOn();

    this.form.get('allOn')?.valueChanges.subscribe(value => {
      this.form.patchValue({
        withCallWaiter: value,
        withCallWaiterToRepeat: value,
        withCallWaiterToPay: value
      }, { emitEvent: false });
    });

    this.form.get('withCallWaiter')?.valueChanges.subscribe(() => this.updateAllOn());
    this.form.get('withCallWaiterToRepeat')?.valueChanges.subscribe(() => this.updateAllOn());
    this.form.get('withCallWaiterToPay')?.valueChanges.subscribe(() => this.updateAllOn());
  }

  updateAllOn(): void {
    const { withCallWaiter, withCallWaiterToRepeat, withCallWaiterToPay } = this.form.value;
    const allOnValue = withCallWaiter && withCallWaiterToRepeat && withCallWaiterToPay;
    this.form.get('allOn')?.setValue(allOnValue, { emitEvent: false });
  }

  someOn(): boolean {
    const { withCallWaiter, withCallWaiterToRepeat, withCallWaiterToPay } = this.form.value;
    return (withCallWaiter || withCallWaiterToRepeat || withCallWaiterToPay);
  }

  onSubmit(): void {
    const config = {
      withCallWaiter: this.form.value.withCallWaiter,
      withCallWaiterToPay: this.form.value.withCallWaiterToPay,
      withCallWaiterToRepeat: this.form.value.withCallWaiterToRepeat
    };

    this.restaurantService.configureCollWaiter(this.restaurantId, config).subscribe((data) => {
      this.dialogRef.close(data as IRestaurantInfo);
    });
  }
}
